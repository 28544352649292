import React, {useState, useEffect} from "react";
import styleFeedback from "./FeedbackContent.module.css"
import TLeft from "../Universal/ContentLable";


const FeedbackContent = () => {

    const [isPopUp, setPopUp] = useState(false);
    const [isFeedbackPopUp, setFeedbackPopUp] = useState(false);

    const turnPopUp = () => {
        setPopUp(!isPopUp);
    }

    const turnFeedbackPopUp = () => {
        setFeedbackPopUp(!isFeedbackPopUp);
    }

    return (
        <>
            <div className={styleFeedback.feedbackContainer}>
                <div className={styleFeedback.description}>
                    <div className={styleFeedback.feedbackHeader}>신문고란?</div>
                    <p>
                        당사 내부고발제도 명칭으로 당사 임직원 등이 회사 또는 다른 임직원의 직무수행 과정에서 발생 할 수 있는 비윤리적 행위 및 위법·위규·부당행위 
                        등에 대해 회사에 신고할 수 있는 내부고발절차로서,회사의 홈페이지, 이메일 등으로 신고 할 수 있는 체계를 말합니다. <br/>
                        신고자의 신분은 엄격히 보호되며 신고한 내용은 회사의 경영개선 및 금융사고 예방을 위하여 적극적으로 수렴하여 반영할 수 있도록 최선을 다하겠습니다.
                    </p>
                    <div className={styleFeedback.feedbackHeader}>신문고 신고대상</div>
                    <p>
                        내부고발 또는 신고대상은 아래와 같습니다. 다만 신고대상에 해당하지 않는 일반 민원성 내용이나 단순 문의성 내용은 처리가 지연되거나 불가할 수 있습니다.
                        <ul>
                            <li>금융사고 발생 및 그 은폐에 관한 사항</li>
                            <li>관련법규 및 사규, 윤리강령 등 위배에 관한 사항</li>
                            <li>회계, 내부회계 및 감사와 관련한 위법·위규·부당행위 등에 관한 사항</li>
                            <li>위법·위규·부당한 업무처리로 손실을 초래하거나 공신력을 저해하는 행위에 관한 사항</li>
                            <li>부당한 업무지시, 비윤리적 행위 및 부정비리 행위 등에 관한 사항</li>
                            <li>기타 위 각호의 사항이 발생할 우려가 있는 경우 등</li>
                        </ul> 
                    </p>
                </div>
                <form action="https://api.web3forms.com/submit" onSubmit={turnFeedbackPopUp} method="POST">
                    <input type="hidden" name="access_key" value="e17b140a-4f85-4bc2-b696-dbe73abaa3ac" />
                    <input type="hidden" name="redirect" value="http://localhost:3000/Feedback"></input>
                    <div className={styleFeedback.feedbackForm}>
                        <div className={styleFeedback.feedbackCase}>
                            <label>
                                유형
                                <em className={styleFeedback.feedbackRed}>*</em>
                            </label>
                            <select required className={styleFeedback.feedbackLine} name="Category">
                                <option value disabled selected>유형을 선택해주세요.</option>
                                <option>금융사고 발생 및 그 은폐에 관한 사항(횡령, 배임 등)</option>
                                <option>윤리강령 등 내부 통제 기준 위배 사항(불공정행위, 경비유용 등)</option>
                                <option>복무규정에 위배되는 사항(상급자의 부당지시, 부당대우, 성희롱 등)</option>
                                <option>위법·부당한 업무처리로 손실을 초래</option>
                                <option>기타(단순 민원 제외)</option>
                            </select>
                        </div>
                        <div className={styleFeedback.feedbackCase}>
                            <label>
                                이메일
                                <em className={styleFeedback.feedbackRed}>*</em>
                            </label>
                            <input type="email" required className={styleFeedback.feedbackLine} name="Email" placeholder="이메일을 입력해주세요." />
                        </div>
                        <div className={styleFeedback.feedbackCase}>
                            <label>
                                휴대폰 번호 
                                <em className={styleFeedback.feedbackRed}>*</em>
                            </label>
                            <input type="tel" required className={styleFeedback.feedbackLine} name="Telephone" placeholder="휴대폰 번호를 입력해주세요."/>
                        </div>
                        <div className={styleFeedback.feedbackCase}>
                            <label>
                                제목 
                                <em className={styleFeedback.feedbackRed}>*</em>
                            </label>
                            <input required className={styleFeedback.feedbackLine} name="Title" placeholder="제목을 입력해주세요."/>
                        </div>
                        <div className={styleFeedback.feedbackCase}>
                            <label>
                                내용  
                                <em className={styleFeedback.feedbackRed}>*</em>
                            </label>
                            <textarea required className={styleFeedback.feedbackLine} name="Message" placeholder="육하원칙(누가, 언제, 어디서, 무엇을, 어떻게, 왜)에 의거하여 구체적으로 작성 부탁드립니다. 타인을 비방하거나 명예를 훼손할 목적의 허위 사실, 음해성 제보의 경우 조사가 진행되지 않을 수 있음을 알려드립니다." />
                        </div>
                        <div className={styleFeedback.feedbackAgreement}>
                            <input required type="checkbox" id="feedbackCheckClick" className={styleFeedback.feedbackAgreebox} />
                            <label className={styleFeedback.feedbackAgreelable} for="feedbackCheckClick">
                                <em className={styleFeedback.feedbackRed}>[필수]</em>
                                개인정보 수집 및 이용에 대한 동의
                            </label>
                            <a className={styleFeedback.feedbackPopupBtn} onClick={turnPopUp}>내용보기</a>
                        </div>
                        <div className={styleFeedback.feedbackMsg}>
                            등록 버튼 클릭 후에는 수정/삭제가 불가하오니, 신고 내용을 제출하시기 전에 다시 한번 확인하여 주시기 바랍니다.
                            <br />
                            내용에 대한 답변은 입력하신 이메일에서 확인하실 수 있습니다.
                        </div>
                        <div className={styleFeedback.feedbackButtonCase}>
                            <button className={styleFeedback.feedbackButon} type="submit">등록</button>
                        </div>
                    </div>
                </form>
                <div className={`${styleFeedback.popCase}`} style={{ display: isPopUp ? 'flex' : 'none' }}>
                    <div className={styleFeedback.popContent}>
                        <h2 className={styleFeedback.popTitle}>개인정보 수집 및 이용에 대한 동의</h2>
                        <p className={styleFeedback.closeBtn} onClick={turnPopUp}>✖</p>
                        <div className={styleFeedback.feedbackPopContIn}>
                            코루파마 홈페이지 서비스를 이용하기 위해 다음과 같이 회사가 본인의 개인정보를 수집∙이용하는 것에 동의합니다.
                            <ol className={styleFeedback.feedbackOllist}>
                                <li>
                                    <em>1. 개인정보 수집항목 </em>
                                    이름, 이메일, 휴대폰 번호
                                </li>
                                <li>
                                    <em>2. 개인정보 이용목적 </em>
                                    신고 내용 확인 및 사후처리 등의 목적을 위해 사용합니다.
                                </li>
                                <li>
                                    <em>3. 개인정보 보유기간 </em>
                                    동의일로부터 1년까지 위 이용 목적을 위해 보유,이용됩니다.
                                </li>
                                <li>
                                    <em>4. 동의 거부 권리 및 거부 시 불이익 </em>
                                    귀하는 동의를 거부하실 수 있습니다.다만 개인정보 수집•이용에 동의를 거부할 경우 의견에 대한 접수 및 처리가 제한될 수 있습니다.
                                </li>
                            </ol>
                        </div>
                        <button className={styleFeedback.feedbackButtonExit} onClick={turnPopUp}>확인</button> 
                    </div>
                </div>
                <div className={styleFeedback.feedbackPopCase} style={{ display: isFeedbackPopUp ? 'flex' : 'none' }}>
                    <div className={styleFeedback.feedbackPopContent}>
                        <p>
                            등록이 완료되었습니다.
                        </p>
                        <button onClick={turnFeedbackPopUp}>닫기</button>
                    </div>
                </div>
            </div>
        </>

    );
};

export default FeedbackContent;