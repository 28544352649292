import React, {useState} from "react";

import {NavLink} from 'react-router-dom';

import StylesFourthPage from './FourthPage.module.css'

import ImgAvalon from '../../../img/picturesMainPage/Homepage_Avalon.png'
import ImgShape from '../../../img/picturesMainPage/Homepage_Shape.png'
import ImgElitox from '../../../img/picturesMainPage/Homepage_Elitox.png'
import {useInView} from "react-intersection-observer";


const FourthPage = () => {
    const [ref, inView] = useInView({
        triggerOnce: false,
    });
    const [animationSLide, setAnimationSlide] = useState(1);

    return (
        <div className={StylesFourthPage.fourthPageBg}>
            <div ref={ref}
                 className={`${StylesFourthPage.fourthDescription} ${inView ? StylesFourthPage.animate0 : ''}`}>
                <h2>SUPERIOR HA FILLER MANUFACTURED THROUGH <br/> CORE TECHNOLOGY MCO® TECH.</h2>
                <div className={StylesFourthPage.fourthLine}></div>
                <p>’AVALON™ HA Filler 시리즈 핵심제조기술인 MCO® Tech.를 바탕으로 에스테틱 산업을 선도하는 기업</p>
            </div>
            <div ref={ref} className={`${StylesFourthPage.fourthImages} ${inView ? StylesFourthPage.animate0 : ''}`}>
                <NavLink className={StylesFourthPage.mobileContainer} to={'/Dermalfillers'}>
                    <div className={StylesFourthPage.fourthCard1}>
                        <div className={StylesFourthPage.fourthCardTitle}>
                            <div className={StylesFourthPage.CardTitle}>
                                AVALON™ HA Filler
                            </div>
                        </div>
                        <img
                            className={`${StylesFourthPage.fourthButton}`}
                            src={ImgAvalon} alt='Product photo'/>
                    </div>
                </NavLink>
                <NavLink className={StylesFourthPage.mobileContainer} to={'/Bodyfiller'}>
                    <div className={StylesFourthPage.fourthCard1}>
                        <div className={StylesFourthPage.fourthCardTitle}>
                            <div className={StylesFourthPage.CardTitle}>
                                Body Filler
                            </div>
                        </div>
                        <img
                            className={`${StylesFourthPage.fourthButton}`}
                            src={ImgShape} alt='Plus sign'/>
                    </div>
                </NavLink>
                <NavLink className={StylesFourthPage.mobileContainer} to={'/Toxins'}>
                    <div className={StylesFourthPage.fourthCard1}>
                        <div className={StylesFourthPage.fourthCardTitle}>
                            <div className={StylesFourthPage.CardTitle}>
                                Botulinum Toxin
                            </div>
                        </div>
                        <img
                            className={`${StylesFourthPage.fourthButton}`}
                            src={ImgElitox} alt='Plus sign'/>
                    </div>
                </NavLink>
                <button className={StylesFourthPage.container} onClick={() => setAnimationSlide(1)}
                        onMouseEnter={() => setAnimationSlide(1)}>
                    <NavLink to={'/Dermalfillers'}>
                        <div className={animationSLide === 1 ? StylesFourthPage.fourthCard1 : StylesFourthPage.fourthCard2}>
                            <div className={animationSLide === 1 ? StylesFourthPage.fourthCardTitle : StylesFourthPage.fourthCardTitleDis}>
                                <div className={StylesFourthPage.CardTitle}>
                                    AVALON™ HA Filler
                                </div>
                            </div>
                            <img
                                className={`${StylesFourthPage.fourthButton} ${animationSLide === 1 ? '' : StylesFourthPage.imageShadow}`}
                                src={ImgAvalon} alt='Product photo'/>
                        </div>
                    </NavLink>
                </button>
                <button className={StylesFourthPage.container} onClick={() => setAnimationSlide(2)}
                        onMouseEnter={() => setAnimationSlide(2)}>
                    <NavLink to={'/Bodyfiller'}>
                        <div className={animationSLide === 2 ? StylesFourthPage.fourthCard1 : StylesFourthPage.fourthCard2}>
                            <div className={animationSLide === 2 ? StylesFourthPage.fourthCardTitle : StylesFourthPage.fourthCardTitleDis}>
                                <div className={StylesFourthPage.CardTitle}>
                                    Body Filler
                                </div>
                            </div>
                            <img
                                className={`${StylesFourthPage.fourthButton} ${animationSLide === 2 ? '' : StylesFourthPage.imageShadow}`}
                                src={ImgShape} alt='Plus sign'/>
                        </div>
                    </NavLink>
                </button>
                <button className={StylesFourthPage.container} onClick={() => setAnimationSlide(3)}
                        onMouseEnter={() => setAnimationSlide(3)}>
                    <NavLink to={'/Toxins'}>
                        <div className={animationSLide === 3 ? StylesFourthPage.fourthCard1 : StylesFourthPage.fourthCard2}>
                            <div className={animationSLide === 3 ? StylesFourthPage.fourthCardTitle : StylesFourthPage.fourthCardTitleDis}>
                                <div className={StylesFourthPage.CardTitle}>
                                    Botulinum Toxin
                                </div>
                            </div>
                            <img
                                className={`${StylesFourthPage.fourthButton} ${animationSLide === 3 ? '' : StylesFourthPage.imageShadow}`}
                                src={ImgElitox} alt='Plus sign'/>
                        </div>
                    </NavLink>
                </button>
            </div>
        </div>
    );
}

export default FourthPage;