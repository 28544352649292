import React, {useState} from "react";
import styleResearch from "./ResearchFieldContent.module.css"
import NextGenFiller from "./ResearchFieldLinks/Next-genFiller"
import HairGrowth from "./ResearchFieldLinks/HairGrowth"
import SkinBooster from "./ResearchFieldLinks/SkinBooster"
import {useInView} from "react-intersection-observer";

const ResearchFieldContent = () => {
    const [activeButton, setActiveButton] = useState("Next-gen Filler");
    const [divContent, setDivContent] = useState(<NextGenFiller/>);
    const ButtonChangeContent = (contentName, ContentComponent) => {
        setDivContent(<ContentComponent/>);
        setActiveButton(contentName);
    }
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    return (
        <>
            <div ref={ref} className={`${styleResearch.topcontent} ${inView ? styleResearch.animate0 : ``}`}>
                <div className={styleResearch.toptext}>
                    <a className={`${styleResearch.button} ${activeButton === "Next-gen Filler" ? styleResearch.active : ''}`}
                        onClick={() => ButtonChangeContent("Next-gen Filler", NextGenFiller)}>
                        Next-gen Filler
                    </a>
                    <a className={`${styleResearch.button} ${activeButton === 'Hair Growth' ? styleResearch.active : ''}`}
                       onClick={() => ButtonChangeContent('Hair Growth', HairGrowth)}>
                        Hair Growth
                    </a>
                    <a className={`${styleResearch.button} ${activeButton === 'Skin Booster' ? styleResearch.active : ''}`}
                       onClick={() => ButtonChangeContent('Skin Booster', SkinBooster)}>
                        Skin Booster
                    </a>
                </div>
            </div>
            <div className={styleResearch.container}>

                {divContent}
            </div>
        </>
    );
};

export default ResearchFieldContent;