import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper'
import { Navigation, Pagination, Scrollbar, A11y, Mousewheel } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import './Fullpage.css';

import FirstPage from './Sliders-content/FirstPage'
import SecondPage from './Sliders-content/SecondPage'
import ThirdPage from './Sliders-content/ThirdPage'
import FourthPage from './Sliders-content/FourthPage'
import FifthPage from './Sliders-content/FifthPage'

import Navimenu from '../Navi-menu/Navi-menu'
import Footer from '../Footer/Footer'

SwiperCore.use([Mousewheel, Pagination]);

export default function Fullpage() {

    const swiperRef = useRef(null);
    const [showPagination, setShowPagination] = useState(true);

    const handleSlideChange = (isBlock) => {
        if (isBlock) {
            swiperRef.current.swiper.enabled = false;
            setShowPagination(false);
        } else {
            swiperRef.current.swiper.enabled = true;
            setShowPagination(true);
        }
    }
    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            switch(index) {
                case 0 :
                    return '<span id="1st" class="' + className + '"> <div class="pagination-name" style="color: #FFFFFF">MAIN</div>'  + '</span>';
                case 1:
                    return '<span class="' + className + '"> <div class="pagination-name">ABOUT US</div>'  + '</span>';
                case 2:
                    return '<span class="' + className + '"> <div class="pagination-name">OUR SCIENCE</div>'  + '</span>';
                case 3:
                    return '<span class="' + className + '"> <div class="pagination-name">PRODUCTS</div>'  + '</span>';
                case 4:
                    return '<span class="' + className + '"> <div class="pagination-name">PRODUCTION</div>'  + '</span>';

                default:
                    return 'No NAME<span class="' + className + '"> '  + '</span>';
            }
        },
    };

    return (
        <>
            <Swiper
                style={{
                    "--swiper-theme-color": "#005ADD",
                    "--swiper-pagination-bullet-size": "10px",
                    "--swiper-pagination-bullet-horizontal-gap": "6px"
                }}
                direction={'vertical'}
                slidesPerView={1}
                spaceBetween={0}
                mousewheel={{
                    sensitivity: 1,
                    releaseOnEdges: true,
                    thresholdDelta: 10
                }}
                pagination={showPagination ? pagination: ''}
                autoplay={{
                    delay: 0, disableOnInteraction: false
                }}
                parallax={true}
                speed={600}
                modules={[Pagination, Navigation, Scrollbar, A11y]}
                className='swiper'
                ref={swiperRef}
            >

            <SwiperSlide className='bg1'>
                <Navimenu 
                    isHome={true}
                    handleSlideChange={handleSlideChange}
                />
                <FirstPage/>
            </SwiperSlide>
            <SwiperSlide>
                <SecondPage/>
            </SwiperSlide>
            <SwiperSlide>
                <ThirdPage/>
            </SwiperSlide>
            <SwiperSlide>
                <FourthPage/>
            </SwiperSlide>
            <SwiperSlide>
                <FifthPage/>
                <Footer
                    isHome={true}
                />
            </SwiperSlide>
        </Swiper>
    </>
    );
}