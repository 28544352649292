import React, {useState} from "react";
import StylesSAU from './SecondAboutUs.module.css'
import {NavLink} from 'react-router-dom';

import Arrow from '../../../../img/icons/mobile-arrow.png'


const SecondAboutUs = ({logo, heading, description, link}) => {


    return (
        <NavLink className={StylesSAU.aboutUsCard} to={link}>
            <img src={logo} alt="About us logo"/>
            <hr className={StylesSAU.line} />
            <div className={StylesSAU.aboutUsText}>
                <h3>{heading}</h3>
                <p>{description}</p>
            </div>
            <NavLink className={StylesSAU.arrowContainer} to={link}><img className={StylesSAU.arrowImage} src={Arrow} alt='Arrow image' /></NavLink>
            <NavLink className={StylesSAU.aboutNav} to={link}>VIEW MORE</NavLink>
        </NavLink>
    );
}

export default SecondAboutUs;