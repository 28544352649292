import React from "react";
import Hbar from '../../Components/PagesComponents/Universal/History-bar'
import TitleBar from '../../Components/PagesComponents/Universal/TopLableBg'
import {NavLink} from "react-router-dom";
import AboutFactoryContent from "../../Components/PagesComponents/Factory/AboutFactoryContent";
import Bg from '../../img/bakcground/BgFactory.png';


const AboutFactory = () => {
    return (
        <>
            <TitleBar kor={"생산"} eng={"Manufacture"}  bgimg={Bg}/>
            <Hbar lvl1={"생산"} lvl2={"공장 소개"}
                  nav1={<li><NavLink to="/Factory">공장 소개</NavLink></li>}>
            </Hbar>
            <AboutFactoryContent/>
        </>
    );
};

export default AboutFactory;