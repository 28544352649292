import React from "react";

import StylesSecondPage from './SecondPage.module.css'
import SecondAboutUs from "./SecondContents/SecondAboutUs";

// Coloured icons
import ICompany from '../../../icons/icons_colour/icons_about company.png';
import ICertifications from '../../../icons/icons_colour/icons_certifications.png';
import INetwork from '../../../icons/icons_colour/icons_global network.png';
import IHistory from '../../../icons/icons_colour/icons_history.png';
import ILocations from '../../../icons/icons_colour/icons_locations.png';

//White icons
import {useInView} from "react-intersection-observer";

const SecondPage = () => {
    const [ref, inView] = useInView({
        triggerOnce: false, // Set to true if you want to trigger the animation only once
    });
    return (
        <div className={StylesSecondPage.secondPageBg}>
            <div ref={ref}
                 className={`${StylesSecondPage.secondHeading} ${inView ? StylesSecondPage.animate0 : ''}`}>
                <h2>ABOUT US</h2>
                <div className={StylesSecondPage.secondLine}></div>
                <p>메디컬 에스테틱 전문기업 코루파마를 소개합니다.</p>
            </div>
            <div ref={ref}
                 className={`${StylesSecondPage.secondAbout} ${inView ? StylesSecondPage.animate0 : ''}`}>
                <SecondAboutUs
                    logo={ICompany}
                    heading='경영철학'
                    description='코루마의 미션과 비전, 핵심가치를 소개합니다.'
                    link='/MissionVision'
                />
                <SecondAboutUs
                    logo={IHistory}
                    heading='연혁'
                    description='코루파마가 걸어온 발자취를 소개합니다'
                    link='/HistoryTimeline'
                />
                <SecondAboutUs
                    logo={ICertifications}
                    heading='기업인증'
                    description='코루파마의 기업인증을 소개합니다.'
                    link='/Certifications'
                />
                <SecondAboutUs
                    logo={INetwork}
                    heading='네트워크'
                    description='코루파마의 글로벌 네트워크를 소개합니다. '
                    link='/Globalnetwork'
                />
                <SecondAboutUs
                    logo={ILocations}
                    heading='사업장 소개'
                    description='코루파마의 사업장을 소개합니다.'
                    link='/Locations'
                />
            </div>
        </div>
    );
}

export default SecondPage;