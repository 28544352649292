import React from "react";
import styleToxin from './ToxinContent.module.css'
import OneItemBox from "../Universal/OneItemBox";
import ElitoxPhoto from "../../../img/Products/Elitox.png"
import ElitoxPhoto2 from "../../../img/Products/Elitox_2.png"

const BodyfillersContent = () => {
    return (
        <>
            <div className={styleToxin.container}>
                <p className={styleToxin.title}>보툴리눔 독소 A형인 <b>elitox®는</b> 근육의 움직임을 제어하는데 도움을 주어 주름 개선 및 라인 정리에 효과적인 제품입니다.</p>
                <div className={styleToxin.product}>
                    <OneItemBox photo={ElitoxPhoto} name={"elitox®"} label1={"Ingredients"}
                                des1={"Botulinum toxin type A"} label2={"Packaging"} des2={"1 vial x 100 units"}/>
                </div>
                <div className={styleToxin.product}>
                    <OneItemBox photo={ElitoxPhoto2} name={"elitox®"} label1={"Ingredients"}
                                des1={"Botulinum toxin type A"} label2={"Packaging"} des2={"1 vial x 200 units"}/>
                </div>
            </div>
            <p className={styleToxin.export}>
                Export only *
            </p>
        </>
        
    );
};

export default BodyfillersContent;