import React from "react";
import styleceo from './CeoContent.module.css'
import Photo from '../../../img/CEO.png'

const Content = () => {
    return (
        <div className={styleceo.global_container}>
            <div className={styleceo.heading}>
                코루파마는 세계로 뻗어 나가는 토탈 메디컬 에스테틱 전문기업으로 성장해 나가고 있습니다.
            </div>
            <div className={styleceo.container}>
                <div className={styleceo.photo}>
                    <img src={Photo} alt="CEO"/>
                </div>
                <div className={styleceo.text}>
                    <p>코루파마는 2016년 설립 이후 ‘Advancing Cosmetology Through Innovation’ 이라는 기업
                        이념에 따라 더 많은 사람이 행복하고 아름다운 삶을 누릴 수 있도록 새로운 메디컬 에스테틱 솔루션을 개척하고 있습니다.</p>
                    <p>필러 라인 'Avalon'과 'Crystal'을 비롯한 다양한 제품을 출시하고, 자체적인 ATP 세미나를 개최하여 당사 제품을
                        더욱 효과적이고 안전 하게 시술하는 지식 공유의 장도 확대해 나가고 있습니다. 당사는 이러한 노력을 통해 세계로 뻗어 나가는 토탈 메디컬 에스테틱 기업으로 도약하고 있습니다.</p>
                    <p>2019년 IFAH Top50 헬스케어 기업 수상, 2019 포브스가 선정한 30세 미만 기업가 선정 등 세계가 주목하는 기업으로
                        거듭나고 있으며 2022년 자체 생산 시설을 준공하고 연구개발을 강화하여 보다 안전하고 수준 높은 제품을 제공하기
                        위해 끊임없이 노력하고 있습니다.</p>
                    <p>당사는 앞으로 한국 메디컬 에스테틱 제품의 우수함을 세계에 알리는데 앞서나가는
                        기업이 되기 위해 노력하겠습니다. 세계를 향한 코루파마의 도전을 함께 응원해 주시기 바랍니다</p>
                    <p>감사합니다.</p>
                    <p className={styleceo.line}></p>
                    <h2>Koru Pharma Co., Ltd.</h2>
                    <h1>CEO Roman Vernidub</h1>
                </div>
            </div>
        </div>
    );
};

export default Content;