import React from "react";

import {NavLink} from 'react-router-dom';
import StylesFifthPage from './FifthPage.module.css'

import ImgFactory from '../../../img/picturesMainPage/Homepage_factory.png'
import {useInView} from "react-intersection-observer";


const FifthPage = () => {
    const [ref, inView] = useInView({
        triggerOnce: false,
    });
    return (
        <div className={StylesFifthPage.fifthPageBg}>
            <div ref={ref}
                 className={`${StylesFifthPage.fifthDescription} ${inView ? StylesFifthPage.animate0 : ''}`}>
                <h2>BEST PRODUCTS OF TODAY <br/>CREATE OUTSTANDING PRODUCTS TOMORROW</h2>
                <div ref={ref}
                     className={StylesFifthPage.fifthLine}
                ></div>
                <p>최고의 제품을 만들기 위해 연구와 혁신을 거듭하는 기업</p>
            </div>
            <div ref={ref}
                 className={`${StylesFifthPage.fifthCard} ${inView ? StylesFifthPage.animate0 : ''}`}>
                <img className={StylesFifthPage.fifthImage} src={ImgFactory} alt='Product photo'/>
                <div className={StylesFifthPage.fifthShadowContainer}>
                    <div className={StylesFifthPage.fifthMore}>
                        <p>코루파마<br/>GMP 인증 제조소</p>
                        <NavLink className={StylesFifthPage.fifthButton} to={'/Factory'}>VIEW MORE</NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FifthPage;