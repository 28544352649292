import React from "react";
import PolicyContent from "../../Components/PagesComponents/FotterLinks/PolicyContent";
import TopLableBG from "../../Components/PagesComponents/Universal/TopLableBg";
import Bg from "../../img/bakcground/BgFooter.png";



const Policy = () => {

    return (
        <>
            <TopLableBG bgimg={Bg} eng={"Privacy policy"} kor={"개인정보처리방침"} />
            <PolicyContent/>
        </>

    );
};

export default Policy;