import React from 'react';

import './Footer.css'
import KoruLogo from '../../icons/LOGO_Colour.png'
import {NavLink} from "react-router-dom"


const Footer = ({ isHome }) => {
    return (
        <footer className={` footer-container ${isHome ? 'add-footer' : ''} `}>
            <div className='footer-information'>
                <img className='koru-logo' src={KoruLogo} alt='Koru Logo'/>
                <div className='footer-contacts'>
                    <ul className='subfooter-contacts'>
                        <li><span>본사:</span> 서울특별시 강남구 봉은사로 468(삼성동 116-1,2) 16층</li>
                        <li><span>춘천공장:</span> 강원도 춘천시 동산면 원무동길 108-1 (동춘천 산업단지)</li>
                        <li><span>연구소:</span> 경기도 하남시 미사대로 540 B동 721 (현대지식산업센터한강미사 2차)</li>
                    </ul>
                    <div className='mobile-container'>
                        <ul className='mobile-container-headers'>
                            <li>본사:</li>
                            <li>춘천공장:</li>
                            <li>연구소:</li>
                        </ul>
                        <ul className='mobile-container-description'>
                            <li>(06081) 서울 강남구 영동대로 616 아남빌딩 4층</li>
                            <li>강원도 춘천시 동산면 원무동길 108-1 (동춘천 산업단지)</li>
                            <li>경기도 하남시 미사대로 540 B동 721 (현대지식산업센터한강미사 2차)</li>
                        </ul>
                    </div>
                    <p>Copyright © Koru Pharma Co., LTD. All Rights Reserved.</p>
                </div>
            </div>
            <ul className='footer-menu'>
                <NavLink className='footer-link' to="/Policy">개인정보처리방침</NavLink>
                <NavLink className='footer-link' to="/Sitemap">사이트맵</NavLink>
                <NavLink className='footer-link' to="/Inquiries">문의</NavLink>
                <NavLink className='footer-link' to="/Feedback">사이버신문고</NavLink>
            </ul>
        </footer>
    );

};

export default Footer;