import React from "react";
import {NavLink} from "react-router-dom";

import Hbar from './Hystory-bar.module.css'
import {ReactComponent as HomeIcon} from '../../../img/R&D/R&D_Home.svg'


const HistoryBar = ({ lvl1, lvl2, nav1, nav2, nav3, nav4, nav5, nav6 }) => {
    return (
        <div className={Hbar.container}>
            <div className={Hbar.home_icon}>
                <HomeIcon className={Hbar.home}>
                    <NavLink to="/Home"></NavLink>
                </HomeIcon>
            </div>
            <div className={Hbar.LVL1text}>
                <a>{lvl1}</a>
                <div className={Hbar.arrow}>˅</div>
                <ul className={Hbar.submenu1}>
                    <li><NavLink to="/Ceo">기업소개</NavLink></li>
                    <li><NavLink to="/Factory">생산</NavLink></li>
                    <li><NavLink to="/Allproducts">제품</NavLink></li>
                    <li><NavLink to="/R&DInstitute">R&D</NavLink></li>
                    <li><NavLink to="/">IR/RP</NavLink></li>
                </ul>
            </div>
            <div className={Hbar.LVL2text}>
                <a>{lvl2}</a>
                <div className={Hbar.arrow}>˅</div>
                <ul className={Hbar.submenu2}>
                    {nav1}
                    {nav2}
                    {nav3}
                    {nav4}
                    {nav5}
                    {nav6}
                </ul>
            </div>
        </div>
    );
};

export default HistoryBar;