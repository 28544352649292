import React from "react";

import StylesThirdPage from './ThirdPage.module.css'
import ThirdContent from './ThirdContents/ThirdContent'

import {useInView} from "react-intersection-observer";


const ThirdPage = () => {
    const [ref, inView] = useInView({
        triggerOnce: false, // Set to true if you want to trigger the animation only once
    });
    return (
        <div className={StylesThirdPage.thirdPageBg}>
            <div
                ref={ref}
                className={`${StylesThirdPage.thirdDescription} ${inView ? StylesThirdPage.animate0 : ''}`}>
                <h2>OUR SCIENCE LEADING <br/> GLOBAL COSMETOLOGY MARKET</h2>
                <div className={StylesThirdPage.thirdLine}></div>
                <p> 메디컬 에스테틱 분야의 선두주자로서 창의적인 사고와 도전으로 글로벌 시장을 이끌어가는 기업</p>
            </div>
            <div ref={ref}
                 className={`${StylesThirdPage.thirdImages} ${inView ? StylesThirdPage.animate0 : ''}`}>
                <ThirdContent
                    picture={StylesThirdPage.Img1}
                    heading='핵심기술'
                    link='/CoreTechnology'
                />
                <ThirdContent
                    picture={StylesThirdPage.Img2}
                    heading='연구활동'
                    link='/ResearchField'
                />
            </div>
        </div>
    );
}

export default ThirdPage;