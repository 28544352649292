import styleSB from "./SkinBooster.module.css"
import {useInView} from "react-intersection-observer";

import SkinFace from "../../../../img/R&D/Research Field/face.png"

import SkinBoosterIcon1 from "../../../../img/R&D/Research Field/Skin Booster_후보 물질 발굴_.png"
import SkinBoosterIcon2 from "../../../../img/R&D/Research Field/Skin Booster_제형 연구_.png"
import SkinBoosterIcon3 from "../../../../img/R&D/Research Field/Skin Booster_비임상 연구_.png"
import SkinBoosterIcon4 from "../../../../img/R&D/Research Field/Skin Booster_임상 연구_.png"
import SkinBoosterIcon5 from "../../../../img/R&D/Research Field/Skin Booster-허가 및 제품화_.png"


const SkinBooster = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    const [ref1, inView1] = useInView({
        triggerOnce: true,
    });
    const [ref2, inView2] = useInView({
        triggerOnce: true,
    });
    return (
        <>
            <div className={styleSB.main}>
                <div ref={ref} className={`${styleSB.box} ${inView ? styleSB.animate0 : ''}`}>
                    <h2>Skin Booster?</h2>
                    <p><b>Skin Booster는</b> 피부 개선에 도움이 되는 유효 성분을 피부에 주입하는 메조테라피 시술용 제품입니다.</p>
                    <img className={styleSB.faceImage} src={SkinFace} alt='Face picture' />
                    <div className={styleSB.faceContainer}>
                        <div className={styleSB.hand}></div>
                        <div className={styleSB.neckline}></div>
                        <div className={styleSB.cervical}></div>
                        <div className={styleSB.face}></div>
                        <div className={styleSB.eyes}></div>
                    </div>
                </div>
                <div className={`${styleSB.line} ${inView ? styleSB.animate0 : ''}`}></div>
                <div ref={ref1} className={`${styleSB.box} ${inView1 ? styleSB.animate0 : ''}`}>
                    <h2>Skin Booster의 시술의 특징과 효과</h2>
                    <p><b>Skin Booster는</b> 주사제나 Micro needle을 이용하여 유효성분을 원하는 부위에 주입하여 상대적으로 높은 효과를 기대할 수 있습니다. 피부의 노화, 흉터, 색소침착 등 다양한 피부 상태를 개선하는 데 효과적입니다</p>
                    <div className={styleSB.numberContainer}>
                        <div className={styleSB.infoBlock}>
                            <div className={styleSB.blueBlock}>1</div>
                            <div className={styleSB.textBlock}>
                                수분 공급
                            </div>
                        </div>
                        <div className={styleSB.infoBlock}>
                            <div className={styleSB.blueBlock}>2</div>
                            <div className={styleSB.textBlock}>
                                세포 재생
                            </div>
                        </div>
                        <div className={styleSB.infoBlock}>
                            <div className={styleSB.blueBlock}>3</div>
                            <div className={styleSB.textBlock}>
                                피부 탄력
                            </div>
                        </div>
                        <div className={styleSB.infoBlock}>
                            <div className={styleSB.blueBlock}>4</div>
                            <div className={styleSB.textBlock}>
                                노화 방지
                            </div>
                        </div>
                        <div className={styleSB.infoBlock}>
                            <div className={styleSB.blueBlock}>5</div>
                            <div className={styleSB.textBlock}>
                                장벽 강화
                            </div>
                        </div>
                        <div className={styleSB.infoBlock}>
                            <div className={styleSB.blueBlock}>6</div>
                            <div className={styleSB.textBlock}>
                                피부 톤
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styleSB.line} ${inView2 ? styleSB.animate0 : ''}`}></div>
                <div ref={ref2} className={`${styleSB.box} ${inView2 ? styleSB.animate0 : ''}`}>
                    <h2>코루파마의 Skin Booster 개발 프로세스</h2>
                    <p>당사는 후보물질 발굴부터 허가, 제품화까지 체계적인 프로세스를 통해 목표 증상 개선을 위한 Skin Booster 제품을 효과적으로 연구 및 개발하고 있습니다.</p>
                    <div className={styleSB.skinBoosterContainer}>
                        <div className={`${styleSB.skinBoosterImageContainer} ${inView2 ? styleSB.animate1 : ''}`}>
                            <img className={styleSB.skinBoosterIcon} src={SkinBoosterIcon1} alt="skin booster image" />
                            <div className={styleSB.skinBoosterImageContainerDesc}>
                                <h5>후보 물질 발굴</h5>
                                <p>Dry & Wet lab 기반 적응증 개선 및 치료 물질 확보</p>
                            </div>
                        </div>
                        <div className={`${styleSB.skinBoosterImageContainer} ${inView2 ? styleSB.animate2 : ''}`}>
                            <img className={styleSB.skinBoosterIcon} src={SkinBoosterIcon2} alt="skin booster image" />
                            <div className={styleSB.skinBoosterImageContainerDesc}>
                                <h5>제형 연구</h5>
                                <p>유효 물질의 제형의 안정성과 효율적인 전달 제형 개발</p>
                            </div>
                        </div>
                        <div className={`${styleSB.skinBoosterImageContainer} ${inView2 ? styleSB.animate3 : ''}`}>
                            <img className={styleSB.skinBoosterIcon} src={SkinBoosterIcon3} alt="skin booster image" />
                            <div className={styleSB.skinBoosterImageContainerDesc}>
                                <h5>비임상 연구</h5>
                                <p>제형의 기초 안전성 및 유효성 자료 확보</p>
                            </div>
                        </div>
                        <div className={`${styleSB.skinBoosterImageContainer} ${inView2 ? styleSB.animate4 : ''}`}>
                            <img className={styleSB.skinBoosterIcon} src={SkinBoosterIcon4} alt="skin booster image" />
                            <div className={styleSB.skinBoosterImageContainerDesc}>
                                <h5>임상 연구</h5>
                                <p>사람을 대상으로 안정성 유효성 약리 효과 확인</p>
                            </div>
                        </div>
                        <div className={`${styleSB.skinBoosterImageContainer} ${inView2 ? styleSB.animate5 : ''}`}>
                            <img className={styleSB.skinBoosterIcon} src={SkinBoosterIcon5} alt="skin booster image" />
                            <div className={styleSB.skinBoosterImageContainerDesc}>
                                <h5>허가 및 제품화</h5>
                                <p>품질관리시스템 확보 및 인허가를 통한 제품화</p>
                            </div>
                        </div>
                        {/* <img className={`${styleSB.skinBoosterImage} ${inView2 ? styleSB.animate1 : ''}`} src={SkinBooster1} alt="skin booster image" /> */}
                        {/* <img className={`${styleSB.skinBoosterImage} ${inView2 ? styleSB.animate2 : ''}`} src={SkinBooster2} alt="skin booster image" />
                        <img className={`${styleSB.skinBoosterImage} ${inView2 ? styleSB.animate3 : ''}`} src={SkinBooster3} alt="skin booster image" />
                        <img className={`${styleSB.skinBoosterImage} ${inView2 ? styleSB.animate4 : ''}`} src={SkinBooster4} alt="skin booster image" />
                        <img className={`${styleSB.skinBoosterImage} ${inView2 ? styleSB.animate5 : ''}`} src={SkinBooster5} alt="skin booster image" /> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SkinBooster;