import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import LogoColour from "../../icons/LOGO_Colour.png"
import LogoWhite from "../../icons/LOGO_White.png"
import { ReactComponent as GlobeColour } from "../../icons/icons_colour/globe_c.svg"
import { ReactComponent as GlobeWhite } from "../../icons/icons_white/globe_w.svg"
import { ReactComponent as BurgerColour } from "../../icons/icons_colour/burger_c.svg"
import { ReactComponent as BurgerWhite } from "../../icons/icons_white/burger_w.svg"
import { ReactComponent as Cross } from '../../icons/cross.svg'

import StylesNaviMenu from './Navi-menu.module.css';
import NavigationMenuBurger from './Burger-menu/Navi-menu-burger'
import NavigationSubmenu from './Navi-submenu/Navi-submenu'

const NavigationMenu = (props) => {

    const [isNavBarVisible, setIsNavBarVisible] = useState(false);
    const [isColour, setIsColour] = useState(false);

    const toggleNavBar = () => {
        setIsNavBarVisible(!isNavBarVisible);
        setIsColour(!isColour);
    }

    return (
        <nav>
            <div 
                onMouseEnter={() => isNavBarVisible ? setIsColour(false) : setIsColour(true)}
                onMouseLeave={() => setIsColour(false)}
                className={`${StylesNaviMenu.navigation_menu} ${props.isHome ? StylesNaviMenu.nav_add : ''} ${isColour ? StylesNaviMenu.white_navigation_menu : ''}`}
            >
                <div className={StylesNaviMenu.logo}>
                    <NavLink to="/Home">
                        {isColour | !props.isHome ? <img src={LogoColour} alt="Company logo"/> : <img src={LogoWhite} alt="Company logo"/>}
                    </NavLink>
                </div>
                <ul className={StylesNaviMenu.nav_bar}>
                    <li>
                        <NavLink className={`${isColour || !props.isHome ? StylesNaviMenu.headerTextColour: StylesNaviMenu.headerText}`} to="/Ceo">기업소개</NavLink>
                    </li>
                    <li>
                        <NavLink className={`${isColour || !props.isHome ? StylesNaviMenu.headerTextColour: StylesNaviMenu.headerText}`} to="/Factory">생산</NavLink>
                    </li>
                    <li>
                        <NavLink className={`${isColour || !props.isHome ? StylesNaviMenu.headerTextColour: StylesNaviMenu.headerText}`} to="/Allproducts">제품</NavLink>
                    </li>
                    <li>
                        <NavLink className={`${isColour || !props.isHome ? StylesNaviMenu.headerTextColour: StylesNaviMenu.headerText}`} to="/R&DInstitute">R&D</NavLink>
                    </li>
                    <li>
                        <NavLink className={`${isColour || !props.isHome ? StylesNaviMenu.headerTextColour: StylesNaviMenu.headerText}`} to="https://korupharma.irpage.co.kr/#/main">IR/PR</NavLink>
                    </li>
                    <div className={StylesNaviMenu.navigation_submenu}>
                        <NavigationSubmenu/>
                    </div>
                </ul>
                <div className={StylesNaviMenu.side_menu}>
                    <NavLink className={StylesNaviMenu.language} to="https://korupharma.com/">
                        <div className={StylesNaviMenu.globe}>
                            {isColour | !props.isHome ? <GlobeColour /> : <GlobeWhite />}
                        </div>
                        <NavLink><span>ENG</span></NavLink>
                    </NavLink>
                    <div className={StylesNaviMenu.burger_menu}>
                        <a onClick={() => {
                            toggleNavBar();
                            if (typeof props.handleSlideChange === "function") { 
                                props.handleSlideChange(true);
                            };
                        }} className={StylesNaviMenu.burger}>
                            {isColour | !props.isHome ?<BurgerColour className={StylesNaviMenu.burgerImg} /> : <BurgerWhite className={StylesNaviMenu.burgerImg} />}
                        </a>
                        {isNavBarVisible && <div onClick={() => {
                            setIsNavBarVisible(!isNavBarVisible);
                            if (typeof props.handleSlideChange === "function") { 
                                props.handleSlideChange(false);
                            };
                        }} className={StylesNaviMenu.shadow_background}></div>}
                        <a onClick={() => {
                            toggleNavBar();
                            if (typeof props.handleSlideChange === "function") { 
                                props.handleSlideChange(false);
                            };
                        }} className={`${StylesNaviMenu.cross} ${isNavBarVisible ? StylesNaviMenu.cross_open : ''}`}><Cross className={StylesNaviMenu.cross_image} /></a>
                        <div className={`${StylesNaviMenu.navigation_menu_burger_container} ${isNavBarVisible ? StylesNaviMenu.navigation_menu_burger_container_open : ''}`}>
                            <NavigationMenuBurger props={() => setIsNavBarVisible(false)} />
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default NavigationMenu;