import React from "react";
import styleHair from './HairBoosterContent.module.css'
import OneItemBox from "../Universal/OneItemBox";
import HBloomPhoto from "../../../img/Products/HBloom.png"

const BodyfillersContent = () => {
    return (
        <>
            <div className={styleHair.globalContainer}>
                <div className={styleHair.container}>
                    <p className={styleHair.title}><b>H Bloom Booster®는</b> 두피 혈행을 원활하게 하여 두피 수분 공급 및 컨디션 개선 효과를 줍니다. <br /><b>NOW CE APPROVED</b> </p>
                    <div className={styleHair.product}>
                        <OneItemBox photo={HBloomPhoto} name={"H BLOOM BOOSTER®"} label1={"Ingredients"}
                                    des1={"HA + Peptides for Scalp"} label2={"Packaging"} des2={"2 syringes x 1 mL"}/>
                    </div>
                </div>
            </div>
            <p className={styleHair.export}>
                Export only *
            </p>
        </>
        
        
    );
};

export default BodyfillersContent;