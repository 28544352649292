import React from "react";
import stylehistory from "./HistoryTimelineContent.module.css"

const MissionVisionContent = () => {

    return (
        <>
            <div className={stylehistory.content}>
            <div className={stylehistory.year}>
                    <div className={stylehistory.yearnumber}>2024</div>
                <div className={stylehistory.line}>
                    <div className={stylehistory.line_container}>
                        <a>07</a>
                        <p>본사 사무실 확장 이전</p>
                    </div>
                    <div className={stylehistory.line_container}>
                        <a>06</a>
                        <p>AVALON PLUS 중국(NMPA) 임상시험 개시</p>
                    </div>
                    <div className={stylehistory.line_container}>
                        <a>06</a>
                        <p>AVALON PLUS 국내 임상시험 개시</p>
                    </div>
                    <div className={stylehistory.line_container}>
                        <a>06</a>
                        <p>신규 히알루론산-키토산 하이드로겔의 제조 방법 및 이를 이용한 필러 조성 특허 등록</p>
                    </div>
                    <div className={stylehistory.line_container}>
                        <a>03</a>
                        <p>러시아 의료기기 제품허가 취득</p>
                    </div>
                </div>
            </div>
                <div className={stylehistory.year}>
                    <div className={stylehistory.yearnumber}>2023</div>
                    <div className={stylehistory.line}>
                        <div className={stylehistory.line_container}>
                            <a>06</a>
                            <p>H Bloom Booster® 헤어필러 자체 생산 개시</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>06</a>
                            <p>히알루론산과 아가 혹은 아가로오스 복합화 하이드로겔 제조방법 특허 출원</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>04</a>
                            <p>elitox® 100 톡신 제품 출시</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>02</a>
                            <p>화학적 가교제가 잔존하지 않는 히알루론산-키토산 하이드로겔 조성물의 제조 방법 특허 출원</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>02</a>
                            <p>의약품 도매업 허가</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>01</a>
                            <p>Mesoheal® Shape 10 mL 바디필러 자체 생산 개시</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>•</a>
                            <p>아프리카 및 아시아 지역으로 판로 확대</p>
                        </div>
                    </div>
                </div>

                <div className={stylehistory.year}>
                    <div className={stylehistory.yearnumber}>2022</div>
                    <div className={stylehistory.line}>
                        <div className={stylehistory.line_container}>
                            <a>12</a>
                            <p>미녹시딜의 포집 또는 용해도 증진용 물질 및 이를 포함하는 탈모증의예방 또는 치료용 약학적 조성물 특허 출원</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>11</a>
                            <p>피부노화 예방, 개선 또는 치료 기능의 신규 폴리펩티드 특허 출원</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>11</a>
                            <p>PPZ 신소재 전용실시권 계약체결</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>09</a>
                            <p>가교제 사용 최소화와 최적물성을 위한 고분자 조성물 및 제조방법 특허 출원</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>09</a>
                            <p>규제전략본부 신설</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>06</a>
                            <p>KGMP획득</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>05</a>
                            <p>ISO 22716 획득</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>03</a>
                            <p>ISO 13485 획득</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>03</a>
                            <p>춘천 제 1 생산공장 준공 및 제조업 허가</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>01</a>
                            <p>하남 R&D 센터 개설</p>
                        </div>
                    </div>
                </div>
                <div className={stylehistory.year}>
                    <div className={stylehistory.yearnumber}>2021</div>
                    <div className={stylehistory.line}>
                        <div className={stylehistory.line_container}>
                            <a>10</a>
                            <p>서울형 강소기업 선정</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>09</a>
                            <p>2021년 대한민국 굿컴퍼니대상 ‘의료기기’ 부문 바이오대상 수상</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>08</a>
                            <p>히알루론산 필러조성물 제조 특허 등록</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>05</a>
                            <p>벤처기업인증</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>02</a>
                            <p>펩타이드와 비오틴 성분의 두피개선 조성물 특허 출원</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>01</a>
                            <p>메인비즈 인증</p>
                        </div>
                    </div>
                </div>
                <div className={stylehistory.year}>
                    <div className={stylehistory.yearnumber}>2020</div>
                    <div className={stylehistory.line}>
                        <div className={stylehistory.line_container}>
                            <a>12</a>
                            <p>연구전담부서 설립</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>12</a>
                            <p>상표권 등록: PROFUSION®, LeedForst®, PROGELCAINE®</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>10</a>
                            <p>생산시설부지 동춘천 상업단지 2,500평 매입</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>07</a>
                            <p>GOST-R (러시아 의료기기) Certificate : Velancia® Thread</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>01</a>
                            <p>ISO 9001, ISO 14001 획득</p>
                        </div>
                    </div>
                </div>
                <div className={stylehistory.year}>
                    <div className={stylehistory.yearnumber}>2019</div>
                    <div className={stylehistory.line}>
                        <div className={stylehistory.line_container}>
                            <a>12</a>
                            <p>IFAH(헬스케어 선진화 국제포럼) Top 50 헬스케어 기업 수상</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>04</a>
                            <p>아시아 Healthcare & Science 2019 포브스가 선정한 30세미만 기업가 선정: ROMAN VERNIDUB ㈜ 코루파마 대표이사</p>
                        </div>
                    </div>
                </div>
                <div className={stylehistory.year}>
                    <div className={stylehistory.yearnumber}>2018</div>
                    <div className={stylehistory.line}>
                        <div className={stylehistory.line_container}>
                            <a>12</a>
                            <p>ATP (Aesthetic Training Program) 의료세미나 진행 : 해외 바이어 ㈜ 코루파마 제품 시술 노하우 및 교육 세미나 진행</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>04</a>
                            <p>Crystal Meso PN 제품 CPNP (유럽화장품 인증) 등록</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>•</a>
                            <p>아프리카 및 아시아 지역으로 판로 확대</p>
                        </div>
                    </div>
                </div>
                <div className={stylehistory.year}>
                    <div className={stylehistory.yearnumber}>2017</div>
                    <div className={stylehistory.line}>
                        <div className={stylehistory.line_container}>
                            <a>09</a>
                            <p>Crystal Meso PN 제품 CPNP (유럽화장품 인증) 등록 상표권 및 특허권 등록</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>•</a>
                            <p>제품런칭: Mesoheal® Line, Plagentic®, Velvet Threads, Crystal Fillers</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>•</a>
                            <p>국제전시회 (신시장 진출) 참가:러시아, UAE, 우크라이나, 중국, 홍콩</p>
                        </div>
                    </div>
                </div>
                <div className={stylehistory.year}>
                    <div className={stylehistory.yearnumber}>2016</div>
                    <div className={stylehistory.line}>
                        <div className={stylehistory.line_container}>
                            <a>09</a>
                            <p>국제전시회 참가: UAE, 미국, 대만, 중국</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>•</a>
                            <p>제품런칭: AVALON™ Fillers, Lipo Lax, Velancia® Threads, LeedFrost®</p>
                        </div>
                        <div className={stylehistory.line_container}>
                            <a>06</a>
                            <p>㈜코루파마 설립</p>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default MissionVisionContent;