import React from "react";
import styleBody from './BodyfillersContent.module.css'
import OneItemBox from "../Universal/OneItemBox";
import MesohealPhoto from "../../../img/Products/MesohealShape.png"

const BodyfillersContent = () => {
    return (
        <>  
            <div className={styleBody.container}>
                <p className={styleBody.title}><b>Mesoheal® Shape 필러는</b> 바디용 히알루론산 필러로, 10 mL 용량의 제품입니다.</p>
                <div className={styleBody.product}>
                    <OneItemBox photo={MesohealPhoto} name={"MESOHEAL® SHAPE"} label1={"Ingredients"}
                                des1={"Cross-linked HA 20 mg/mL"} label2={"Packaging"} des2={"1 syringe x 10 mL "}/>
                </div>
            </div>
            <p className={styleBody.export}>
                Export only *
            </p>
        </>
    );
};

export default BodyfillersContent;