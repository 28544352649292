import React from "react";
import SitemapContent from "../../Components/PagesComponents/FotterLinks/SitemapContent";
import TopLableBG from "../../Components/PagesComponents/Universal/TopLableBg";
import Bg from "../../img/bakcground/BgFooter.png";



const Sitemap = () => {

    return (
        <>
            <TopLableBG bgimg={Bg} eng={"Sitemap"} kor={"사이트맵"} />
            <SitemapContent/>
        </>

    );
};

export default Sitemap;