import React from "react";
import Hbar from '../../Components/PagesComponents/Universal/History-bar'
import TitleBar from '../../Components/PagesComponents/Universal/TopLableBg'
import {NavLink} from "react-router-dom";
import Bg from "../../img/R&D/R&D_Header.png";
import CoreTechnologyContent from "../../Components/PagesComponents/R&D/CoreTechnologyContent";


const CoreTechnology = () => {
    return (
        <>
            <TitleBar kor={"핵심기술"} eng={"Core Technology"} bgimg={Bg}/>
            <Hbar lvl1={"R&D"} lvl2={"핵심기술"}
                  nav1={<li><NavLink to="/R&DInstitute">연구소 소개</NavLink></li>}
                  nav2={<li><NavLink to="/CoreTechnology">핵심기술</NavLink></li>}
                  nav3={<li><NavLink to="/ResearchField">연구활동</NavLink></li>}>
            </Hbar>
            <CoreTechnologyContent/>
        </>
    );
};
export default CoreTechnology;