import React from "react";
import styleBox from './DermaItemBox.module.css'

const DermalfillersBox = ({ photo, name, label1, des1, label2, des2 }) => {
    return (
        <div className={styleBox.item}>
            <div className={styleBox.photo}><img src={photo}/></div>
            <div className={styleBox.name}>{name}</div>
            <div className={styleBox.label}>{label1}</div>
            <div className={styleBox.description}>{des1}</div>
            <div className={styleBox.label}>{label2}</div>
            <div className={styleBox.description}>{des2}</div>
        </div>
    );
};

export default DermalfillersBox;