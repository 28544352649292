import React from "react";
import StylesRD from "./R&DInstituteContent.module.css"
import {useInView} from "react-intersection-observer";

const LabContent = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    const [ref1, inView1] = useInView({
        triggerOnce: true,
    });
    const [ref2, inView2] = useInView({
        triggerOnce: true,
    });
    return (
        <>
            <div className={StylesRD.container}>
                <div ref={ref}
                     className={`${StylesRD.subContainer} ${inView ? StylesRD.animate0 : ''}`}>
                    <div className={`${StylesRD.contentBlock}`}>
                        <div id={StylesRD.imageFirst}></div>
                        <div className={StylesRD.text}>
                            <div>
                                <b>MCO® Tech.를</b> 활용한 인체 조직 수복용 생체 재료를 비롯한 다양한 미용 관련 의료기기, 메조테라피 및 코스메틱 제품 등의 Soft matter 제형과 신소재 개발 성과를 이루어 왔습니다.
                            </div>
                        </div>
                    </div>
                    <div className={`${StylesRD.contentBlock}`}>
                        <div id={StylesRD.imageSecond}></div>
                        <div className={StylesRD.text}>
                            <div>
                                더욱 진보된 바이오 기술, 나노 테크놀로지, 고분자 합성기술 등을 적용하고 융합 하여 <b>새로운 솔루션을 제시하고 고기능성 제품을 개발</b>해 글로벌 코슈메디컬 분야를 지속적으로 선도해 나가겠습니다.
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={ref1}
                     className={`${StylesRD.subContainer} ${inView1 ? StylesRD.animate0 : ''}`}>
                    <div id={StylesRD.imageThird}></div>
                    <div id={StylesRD.imageFourth}></div>
                </div>
                <div ref={ref2}
                     className={`${StylesRD.subContainer} ${inView2 ? StylesRD.animate0 : ''}`}>
                    <div id={StylesRD.imageFifth}></div>
                </div>
            </div>
        </>
    );
};

export default LabContent;