import styleHG from "./HairGrowth.module.css"
import {useInView} from "react-intersection-observer";
import Testosterone from "../../../../img/R&D/Research Field/Hair Growth 1.png"
import DHT from "../../../../img/R&D/Research Field/Hair Growth 2.png"
import HairGrowth from "../../../../img/R&D/Research Field/Hair Growth-01.jpg"


const ResearchFieldContent = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    const [ref1, inView1] = useInView({
        triggerOnce: true,
    });
    const [ref2, inView2] = useInView({
        triggerOnce: true,
    });
    return (
        <div className={styleHG.main}>
            <div ref={ref} className={`${styleHG.container} ${inView ? styleHG.animate0 : ''}`}>
                <h2 className={styleHG.mainHeading}>Hair Growth Filler?</h2>
                <p className={styleHG.text}><b>Hair Growth Filler는</b> 경구 또는 두피에 직접 바르는 제품과 달리 주사제를 통해 사용자의 두피 내로 직접 주입하여 편리함과 모발 촉진 효능을 높인
                    치료제입니다.</p>
            </div>
            <div className={`${styleHG.line} ${inView ? styleHG.animate0 : ''}`}></div>
            <div ref={ref1} className={`${styleHG.container2}`}>
                <h2 className={`${styleHG.opacityFlag} ${styleHG.mainHeading} ${inView1 ? styleHG.animate0 : ''}`}>탈모의 메카니즘</h2>
                <p className={`${styleHG.opacityFlag} ${inView1 ? styleHG.animate0 : ''}`}><b>Hair Growth Filler는</b> 피부 개선에 도움이 되는 유효 성분을 피부에 주입하는 메조테라피 시술용 제품입니다.</p>
                <img className={`${styleHG.hairGrowthImage} ${inView1 ? styleHG.animate0 : ''}`} src={HairGrowth} alt='Hair Growth Image'></img>
                <div className={styleHG.filler}>
                    <div className={`${styleHG.fillerContainer}`}>
                        <div className={`${styleHG.fillerContainerInfo} ${inView1 ? styleHG.animate1 : ''}`}>
                            <p className={styleHG.fillerUpperTitle}>테스토스테론</p>
                            <p className={styleHG.fillerDownTitle}>(Testosterone)</p>
                            <img src={Testosterone} className={styleHG.fillerImage} />
                        </div>
                        <div className={`${styleHG.fillerContainerInfoArrow} ${inView1 ? styleHG.animate2 : ''}`}>
                            <p className={styleHG.fillerUpperTitle}>5알파환원효소</p>
                            <p>(5α-Reductase)</p>
                            <div className={styleHG.arrow}></div>
                            <div className={styleHG.triangle}></div>
                        </div>
                        <div className={`${styleHG.fillerContainerInfo} ${inView1 ? styleHG.animate3 : ''}`}>
                            <p className={styleHG.fillerUpperTitle}>디하이드로테스토스테론</p>
                            <p className={styleHG.fillerDownTitle}>(Dihydrotestosterone-DHT)</p>
                            <img src={DHT} className={styleHG.fillerImage} />
                        </div>
                    </div>
                    <div className={`${styleHG.fillerDescription} ${inView1 ? styleHG.animate4 : ''}`}>
                        <div className={styleHG.fillerBlock}>
                            <p>남성호르몬의</p>
                            <p>테스토스테론 과다 분비</p>
                        </div>
                        <div className={styleHG.fillerArrow}></div>
                        <div className={styleHG.fillerBlock}>
                            <p>5α-환원효소와 결합해</p>
                            <p>DHT 를 다량합성</p>
                        </div>
                        <div className={styleHG.fillerArrow}></div>
                        <div className={styleHG.fillerBlock}>
                            <p>DHT는 모모세포에 있는</p>
                            <p>안드로겐 수용체에 결합</p>
                        </div>
                        <div className={styleHG.fillerArrow}></div>
                        <div className={styleHG.fillerBlock}>
                            <p>모발증식 촉진인자 감소</p>
                            <p>및 모낭의 퇴화</p>
                        </div>
                        <div className={styleHG.fillerArrow}></div>
                    </div>
                </div>
                <p className={styleHG.text}>탈모는 진행하는 질환의 하나로, 모발의 주기에 이상이 생겨 모발이 빠지면서 발생합니다.<br/>발생하는 원인에 따라 남성형 탈모, 여성형 탈모, 원형 탈모, 산욕기 탈모, 동반된
                    피부질환에 따른 탈모 등으로 구분됩니다.</p>
            </div>
            <div className={`${styleHG.line} ${inView2 ? styleHG.animate0 : ''}`}></div>
            <div ref={ref2} className={`${styleHG.container} ${inView2 ? styleHG.animate0 : ''}`}>
                <h2 className={styleHG.mainHeading}>Hair Growth Filler 연구개발 방향</h2>
                <p className={styleHG.text}>당사는 혁신적인 기능성 원료의 조합기술을 통해 모근 활성 성분과 펩타이드를 지속적으로 방출시켜 모낭의 퇴화를 감소시키고 모발 성장을 촉진, 이상적인 발모를 완성할 수 있는 제품을
                    개발하고 있습니다.</p>
                <h3>Hair Growth Filler 적응증</h3>
                <div className={styleHG.numberContainer}>
                    <div className={styleHG.infoBlock}>
                        <div className={styleHG.blueBlock}>1</div>
                        <div className={styleHG.textBlock}>
                            두피 내 혈액순환이 <br/>
                            원활하지 않을 때
                        </div>
                    </div>
                    <div className={styleHG.infoBlock}>
                        <div className={styleHG.blueBlock}>2</div>
                        <div className={styleHG.textBlock}>
                            모낭의 활성이 <br/>
                            필요할 때
                        </div>
                    </div>
                    <div className={styleHG.infoBlock}>
                        <div className={styleHG.blueBlock}>3</div>
                        <div className={styleHG.textBlock}>
                            모발이 약하거나 모발에 <br/>
                            힘이 없을 때
                        </div>
                    </div>
                    <div className={styleHG.infoBlock}>
                        <div className={styleHG.blueBlock}>4</div>
                        <div className={styleHG.textBlock}>
                            건성두피
                        </div>
                    </div>
                    <div className={styleHG.infoBlock}>
                        <div className={styleHG.blueBlock}>5</div>
                        <div className={styleHG.textBlock}>
                            탈모증 환자 등
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResearchFieldContent;