import React from "react";
import styleDerma from './DermalfillersContent.module.css'
import DermalfillersBox from "../Universal/DermaItemBox";
import OneItemBoxAvalon from "../Universal/OneItemBoxAvalon"

import AvalonFinePhoto from '../../../img/Products/AvalonFine.png'
import AvalonFinePlusPhoto from '../../../img/Products/AvalonFinePlus.png'
import AvalonVitalPhoto from '../../../img/Products/AvalonVital.png'
import AvalonVitalPlusPhoto from '../../../img/Products/AvalonVitalPlus.png'
import AvalonUltraPlusPhoto from '../../../img/Products/AvalonUltraPlus.png'
import AvalonGrandPlusPhoto from '../../../img/Products/AvalonGrandPlus.png'
import CrystalLitePhoto from '../../../img/Products/CystalLite.png'
import CrystalDeepPhoto from '../../../img/Products/CystalDeep.png'
import CrystalUltraPhoto from '../../../img/Products/CystalUltra.png'

const DermalfillersContent = () => {
    const Crystal = {
        width: 'auto' };
    return (
        <>
            <div className={styleDerma.globalContainer}>
                <div className={styleDerma.container}>
                    <div className={styleDerma.infoContainer}>
                        <div className={styleDerma.title}>Avalon Fillers</div>
                        <div className={styleDerma.text}>
                            <b>Avalon™ 필러는</b> 얼굴에 사용하기 적합한 히알루론산 필러입니다. 코루파마 독자기술로 만들어졌으며, 다양한 제품 구성으로 필요한 곳에 알맞게 사용하실 수  있습니다.
                        </div>
                    </div>
                    <div className={`${styleDerma.avalonContainers} ${styleDerma.gridNet}`}>
                        <DermalfillersBox photo={AvalonFinePhoto} name={"AVALON™ FINE"} label1={"Ingredients"}
                                        des1={"Cross-linked HA 20 mg/mL"} label2={"Packaging"}
                                        des2={"1 syringe x 1 mL "}/>
                        <DermalfillersBox photo={AvalonFinePlusPhoto} name={"AVALON™ FINE PLUS"} label1={"Ingredients"}
                                        des1={"Cross-linked HA 20 mg/mL \n" + "Lidocaine 0.3%"} label2={"Packaging"}
                                        des2={"1 syringe x 1 mL "}/>
                        <DermalfillersBox photo={AvalonVitalPhoto} name={"AVALON™ VITAL"} label1={"Ingredients"}
                                        des1={"Cross-linked HA 20 mg/mL"} label2={"Packaging"}
                                        des2={"1 syringe x 1 mL "}/>
                        <DermalfillersBox photo={AvalonVitalPlusPhoto} name={"AVALON™ VITAL PLUS"} label1={"Ingredients"}
                                        des1={"Cross-linked HA 20 mg/mL \n" + "Lidocaine 0.3%"} label2={"Packaging"}
                                        des2={"1 syringe x 1 mL "}/>
                        <DermalfillersBox photo={AvalonUltraPlusPhoto} name={"AVALON™ ULTRA PLUS"} label1={"Ingredients"}
                                        des1={"Cross-linked HA 20 mg/mL\n" + "Lidocaine 0.3%"} label2={"Packaging"}
                                        des2={"1 syringe x 1 mL "}/>
                        <DermalfillersBox photo={AvalonGrandPlusPhoto} name={"AVALON™ GRAND PLUS"} label1={"Ingredients"}
                                        des1={"Cross-linked HA 24 mg/mL \n" + "Lidocaine 0.3%"} label2={"Packaging"}
                                        des2={"1 syringe x 1 mL "}/>

                    </div>
                    <div className={`${styleDerma.avalonContainersDesktop} ${styleDerma.gridNet}`}>
                        <div className={styleDerma.product}>
                            <OneItemBoxAvalon photo={AvalonFinePhoto} name={"AVALON™ FINE"} label1={"Ingredients"}
                                        des1={"Cross-linked HA 20 mg/mL"} label2={"Packaging"} des2={"1 syringe x 1 mL "}/>
                        </div>
                        <div className={styleDerma.product}>
                            <OneItemBoxAvalon photo={AvalonFinePlusPhoto} name={"AVALON™ FINE PLUS"} label1={"Ingredients"}
                                        des1={"Cross-linked HA 20 mg/mL \n" + "Lidocaine 0.3%"} label2={"Packaging"} des2={"1 syringe x 1 mL "}/>
                        </div>
                        <div className={styleDerma.product}>
                            <OneItemBoxAvalon photo={AvalonVitalPhoto} name={"AVALON™ VITAL"} label1={"Ingredients"}
                                        des1={"Cross-linked HA 20 mg/mL"} label2={"Packaging"} des2={"1 syringe x 1 mL "}/>
                        </div>
                        <div className={styleDerma.product}>
                            <OneItemBoxAvalon photo={AvalonVitalPlusPhoto} name={"AVALON™ VITAL PLUS"} label1={"Ingredients"}
                                        des1={"Cross-linked HA 20 mg/mL \n" + "Lidocaine 0.3%"} label2={"Packaging"} des2={"1 syringe x 1 mL "}/>
                        </div>
                        <div className={styleDerma.product}>
                            <OneItemBoxAvalon photo={AvalonUltraPlusPhoto} name={"AVALON™ ULTRA PLUS"} label1={"Ingredients"}
                                        des1={"Cross-linked HA 20 mg/mL\n" + "Lidocaine 0.3%"} label2={"Packaging"} des2={"1 syringe x 1 mL "}/>
                        </div>
                        <div className={styleDerma.product}>
                            <OneItemBoxAvalon photo={AvalonGrandPlusPhoto} name={"AVALON™ GRAND PLUS"} label1={"Ingredients"}
                                        des1={"Cross-linked HA 24 mg/mL \n" + "Lidocaine 0.3%"} label2={"Packaging"} des2={"1 syringe x 1 mL "}/>
                        </div>
                    </div>
                    <p className={styleDerma.export}>
                        Export only *
                    </p>
                </div>
                <div className={styleDerma.line}></div>
                <div className={styleDerma.container}>
                    <div className={styleDerma.infoContainer}>
                        <div className={styleDerma.title}>Crystal Fillers</div>
                        <div className={styleDerma.text}><b>Crystal</b> 필러는 얼굴에 사용하기 적합한 히알루론산 필러입니다. 코루파마 독자기술로 만들어졌으며, 전 제품
                            리도카인을 포함하고 있습니다.
                        </div>
                    </div>
                    <div className={styleDerma.gridNet}>
                        <DermalfillersBox photo={CrystalLitePhoto} name={"Crystal Lite"} label1={"Ingredients"}
                                        des1={"Cross-linked HA 20 mg/mL \n" + "Lidocaine 0.3%"}  label2={"Packaging"}
                                        des2={"1 syringe x 1 mL "}/>
                        <DermalfillersBox photo={CrystalDeepPhoto} name={"Crystal deep"} label1={"Ingredients"}
                                        des1={"Cross-linked HA 20 mg/mL \n" + "Lidocaine 0.3%"} label2={"Packaging"}
                                        des2={"1 syringe x 1 mL "}/>
                        <DermalfillersBox photo={CrystalUltraPhoto} name={"Crystal ultra"} label1={"Ingredients"}
                                        des1={"Cross-linked HA 20 mg/mL \n" + "Lidocaine 0.3%"}  label2={"Packaging"}
                                        des2={"1 syringe x 1 mL "}/>
                    </div>
                    <p className={styleDerma.export}>
                        Export only *
                    </p>
                </div>
            </div>
        </>
    );
};

export default DermalfillersContent;