import React from "react";
import Hbar from '../../Components/PagesComponents/Universal/History-bar'
import TitleBar from '../../Components/PagesComponents/Universal/TopLableBg'
import CeoContent from "../../Components/PagesComponents/AboutAsUnique/CeoContent";
import {NavLink} from "react-router-dom";
import Bg from '../../img/bakcground/BgAbout.png';



const CEO = () => {
    return (
        <>
            <TitleBar kor={"CEO 인사말"} eng={"CEO's Message"} bgimg={Bg}/>
            <Hbar lvl1={"기업소개"} lvl2={"CEO 인사말"}
                  nav1={<li><NavLink to="/Ceo">CEO 인사말</NavLink></li>}
                  nav2={<li><NavLink to="/MissionVision">경영철학</NavLink></li>}
                  nav3={<li><NavLink to="/HistoryTimeline">연혁</NavLink></li>}
                  nav4={<li><NavLink to="/Certifications">기업인증</NavLink></li>}
                  nav5={<li><NavLink to="/Globalnetwork">글로벌 네트워크</NavLink></li>}
                  nav6={<li><NavLink to="/Locations">사업장 소개</NavLink></li>}/>
            <CeoContent/>
        </>
    );
};

export default CEO;