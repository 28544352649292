import React from "react";
import TitleLeft from "../Universal/ContentLable";
import styleProduct from './Allproducts.module.css';
import {NavLink} from "react-router-dom";
import DermaFillerPhoto from '../../../img/Products/Fillers.jpg';
import BodyFillerPhoto from '../../../img/Products/BodyFiller.png';
import HairFillerPhoto from '../../../img/Products/HairBooster.png';
import ToxinPhoto from '../../../img/Products/Toxin.png';
import pdf1 from './20220920_Koru Brochure_for ST_KOR.pdf';
import pdf2 from './20221031_AVALON_Catalogue_ENG.pdf';


const AllproducContent = () => {
    return (
        <div className={styleProduct.container}>
            <h2>
                전체 제품
            </h2>
            <div className={styleProduct.net}>
                <NavLink className={styleProduct.product} to="/Dermalfillers/">
                    <div className={styleProduct.photo}><img src={DermaFillerPhoto}/></div>
                    <div className={styleProduct.label}>
                        <h1>안면필러 </h1>
                        <span></span>
                        <p>Dermal Fillers</p>
                        <div className={styleProduct.aContainer}>
                            <div className={styleProduct.a}>Learn more</div>
                            <div className={styleProduct.aSign}>{">"}</div>
                        </div>
                    </div>
                </NavLink>
                <NavLink className={styleProduct.product} to="/Bodyfiller/">
                    <div className={styleProduct.photo}><img src={BodyFillerPhoto}/></div>
                    <div className={styleProduct.label}>
                        <h1>바디필러</h1>
                        <span></span>
                        <p>body fillers</p>
                        <div className={styleProduct.aContainer}>
                            <div className={styleProduct.a}>Learn more</div>
                            <div className={styleProduct.aSign}>{">"}</div>
                        </div>
                    </div>
                </NavLink>
            </div>
            <div className={styleProduct.net}>
                <NavLink className={styleProduct.product} to="/Hairbooster/">
                    <div className={styleProduct.photo}><img src={HairFillerPhoto}/></div>
                    <div className={styleProduct.label}>
                        <h1>헤어부스터  </h1>
                        <span></span>
                        <p>hair booster</p>
                        <div className={styleProduct.aContainer}>
                            <div className={styleProduct.a}>Learn more</div>
                            <div className={styleProduct.aSign}>{">"}</div>
                        </div>
                    </div>
                </NavLink>
                <NavLink className={styleProduct.product} to="/Toxins/">
                    <div className={styleProduct.photo}><img src={ToxinPhoto}/></div>
                    <div className={styleProduct.label}>
                        <h1>톡신</h1>
                        <span></span>
                        <p>Toxins</p>
                        <div className={styleProduct.aContainer}>
                            <div className={styleProduct.a}>Learn more</div>
                            <div className={styleProduct.aSign}>{">"}</div>
                        </div>
                    </div>
                </NavLink>
            </div>
            <div className={styleProduct.net}>
                <a className={styleProduct.product} href={pdf1} download>
                    <div style={{display:"flex"}} className={styleProduct.label}>
                        <h1>Portfolio Brochure</h1>
                        <a>Download</a>

                    </div>
                </a>
                <a className={styleProduct.product} href={pdf2} download>
                    <div style={{display:"flex"}} className={styleProduct.label}>
                        <h1>Avalon Catalogue</h1>
                        <a>Download</a>
                    </div>
                </a>
            </div>
        </div>
    );
};

export default AllproducContent;