import React from "react";
import FeedbackContent from "../../Components/PagesComponents/FotterLinks/FeedbackContent";
import TopLableBG from "../../Components/PagesComponents/Universal/TopLableBg";
import Bg from "../../img/bakcground/BgFooter.png";

const Feedback = () => {

    return (
        <>
            <TopLableBG bgimg={Bg} kor={"사이버 신문고"} />
            <FeedbackContent />
        </>

    );
};

export default Feedback;