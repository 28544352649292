import React from "react";
import styleBox from './OneItemBox.module.css'

const OneItemBox = ({ photo, name, label1, des1, label2, des2 }) => {
    return (
        <>
            <div className={styleBox.photo}><img src={photo}/></div>
            <div className={styleBox.item}>
                <div className={styleBox.name}>{name}</div>
                <div className={styleBox.container}>
                    <div className={styleBox.label}>{label1}</div>
                    <div className={styleBox.description}>{des1}</div>
                </div>
                <div className={styleBox.container}>
                    <div className={styleBox.label}>{label2}</div>
                    <div className={styleBox.description}>{des2}</div>
                </div>
            </div>
        </>
    );
};

export default OneItemBox;