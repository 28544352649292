import React from "react";
import StyleMission from "./MissionVisionContent.module.css";


const MissionVisionContent = () => {

    return (
        <>
            <div className={StyleMission.content}>
                <div className={StyleMission.topContent}>
                    <div>
                        <h2>미션</h2>
                        <p>인류가 아름답고 행복한 삶을 누릴 수 있도록 메디컬 에스테틱 <br/> 분야에서 지속적인 연구개발로 새로운 메디컬 에스테틱 솔루션을 개척한다.</p>
                    </div>
                    <div>
                        <h2>비전</h2>
                        <p>신뢰할 수 있는 브랜드가치 확보<br/> 신물질 개발로 시장을 선도하는 글로벌 리더</p>
                        <p>국내 10대 메디컬 에스테틱 기업</p>
                    </div>  
                </div>
                <div className={StyleMission.line}></div>
                <div className={StyleMission.container}>
                    <div className={StyleMission.subContainer}>
                        <div className={StyleMission.topContent}>
                            <h2>핵심 가치</h2>
                        </div>
                        <div className={StyleMission.numberContainers}>
                            <div className={StyleMission.numberContainer}>
                                <div>
                                    <p className={StyleMission.number}>01</p>
                                    <p className={StyleMission.text}><b>열린 소통과 새로운 시도</b></p>
                                </div>
                                <p className={StyleMission.text}>고객과 소통을 통해 최상의 솔루션을 제공하고 새로운 기회를 발견한다.</p>
                            </div>
                            <div className={StyleMission.numberContainer}>
                                <div>
                                    <p className={StyleMission.number}>02</p>
                                    <p className={StyleMission.text}><b>주인의식</b></p>
                                </div>
                                <p className={StyleMission.text}>회사와 제품, 팀에 대한 자부심을 가지고 주도적으로 책임을 다한다.</p>
                            </div>
                        </div>
                    </div>
                    <div className={StyleMission.numberContainers}>
                        <div className={StyleMission.numberContainer}>
                            <div>
                                <p className={StyleMission.number}>03</p>
                                <p className={StyleMission.text}><b>글로벌 지향</b></p>
                            </div>
                            <p className={StyleMission.text}>능력과 인성을 겸비한 글로벌 인재를 육성하고 전 세계로 뻗어 나간다.</p>
                        </div>
                        <div className={StyleMission.numberContainer}>
                            <div>
                                <p className={StyleMission.number}>04</p>
                                <p className={StyleMission.text}><b>품질확보</b></p>
                            </div>
                            <p className={StyleMission.text}>첨단 생산기술과 과학적 데이터를 바탕으로 일정하고 안전한 품질의 제품을 약속한다.</p>
                        </div>
                        <div className={StyleMission.numberContainer}>
                            <div>
                                <p className={StyleMission.number}>05</p>
                                <p className={StyleMission.text}><b>월드 베스트  신기술에 도전</b></p>
                            </div>
                            <p className={StyleMission.text}>끊임없는 신기술 창조 및 업그레이드로 World's Best 기술력을 지속적으로 확보한다.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default MissionVisionContent;