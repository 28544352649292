import React, {useLayoutEffect} from "react";
import {Route, Routes, BrowserRouter, useLocation} from 'react-router-dom';

//Component
import Navimenu from './Navi-menu/Navi-menu'
import Footer from './Footer/Footer'
import Home from './Fullpage/Fullpage'
import Ceo from '../Pages/AboutKoruPharma/CEO';
import MissionVision from "../Pages/AboutKoruPharma/MissionVision";
import Certifications from "../Pages/AboutKoruPharma/Certifications";
import Globalnetwork from "../Pages/AboutKoruPharma/Globalnetwork";
import Locations from "../Pages/AboutKoruPharma/Locations";
import HistoryTimeline from "../Pages/AboutKoruPharma/HistoryTimeline";
import Allproducts from "../Pages/Products/Allproducts"
import Bodyfiller from "../Pages/Products/Bodyfiller,";
import Dermalfillers from "../Pages/Products/Dermalfillers";
import Hairbooster from "../Pages/Products/Hairbooster";
import Toxins from "../Pages/Products/Toxins";
import Factory from "../Pages/AboutFactory/AboutFactory";
import Lab  from "../Pages/R&D/R&DInstitute";
import Lab1  from "../Pages/R&D/CoreTechnology";
import Lab2  from "../Pages/R&D/ResearchField";
import Policy from "../Pages/FooterPages/Policy";
import Inquiries from "../Pages/FooterPages/Inquiries";
import Sitemap from "../Pages/FooterPages/Sitemap";
import Feedback from "../Pages/FooterPages/Feedback"
import Page404 from "../Pages/Page404/Page404"


const Routecomp = () => {
    const location = useLocation();
    const TopTP = ({children}) => {
        const location = useLocation();

        useLayoutEffect(() => {
            document.documentElement.scrollTo(0, 0);
        }, [location.pathname]);

        return children
    }

    return (
        <>  
            {location.pathname !== '/Home' && location.pathname !== '/' && location.pathname !== '/Home/' ? <Navimenu 
                isHome={false}/> : <></>}
            <TopTP/>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/Home" element={<Home/>}/>
                    <Route path="/Home/" element={<Home/>}/>
                    <Route path="/Ceo/" element={<Ceo/>}/>
                    <Route path="/MissionVision/" element={<MissionVision/>}/>
                    <Route path="/Certifications/" element={<Certifications/>}/>
                    <Route path="/Locations/" element={<Locations/>}/>
                    <Route path="/HistoryTimeline/" element={<HistoryTimeline/>}/>
                    <Route path="/Globalnetwork/" element={<Globalnetwork/>}/>
                    <Route path="/Allproducts/" element={<Allproducts/>}/>
                    <Route path="/Bodyfiller/" element={<Bodyfiller/>}/>
                    <Route path="/Dermalfillers/" element={<Dermalfillers/>}/>
                    <Route path="/Hairbooster/" element={<Hairbooster/>}/>
                    <Route path="/Toxins/" element={<Toxins/>}/>
                    <Route path="/Factory/" element={<Factory/>}/>
                    <Route path="/R&DInstitute/" element={<Lab/>}/>
                    <Route path="/CoreTechnology/" element={<Lab1/>}/>
                    <Route path="/ResearchField/" element={<Lab2/>}/>
                    <Route path="/Policy/" element={<Policy/>}/>
                    <Route path="/Inquiries/" element={<Inquiries/>}/>
                    <Route path="/Sitemap/" element={<Sitemap/>}/>
                    <Route path="/Feedback/" element={<Feedback/>}/>
                    <Route path="*" element={<Page404/>} status={404} />
                </Routes>
            
            {location.pathname !== '/Home' && location.pathname !== '/' && location.pathname !== '/Home/' ? <Footer 
                isHome={false}/> : <></>}
        </>
    );
};

export default Routecomp;