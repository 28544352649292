import React from "react";
import styleInquir from "./InquiriesContent.module.css"
import TLeft from "../Universal/ContentLable";
import {ReactComponent as Geo} from "../../../icons/old_icons/geo.svg";
import {ReactComponent as Tel} from "../../../icons/old_icons/Tel.svg";
import {ReactComponent as Fax} from "../../../icons/old_icons/fax.svg";
import {ReactComponent as Mail} from "../../../icons/old_icons/email.svg";


const InquiriesContent = () => {

    return (
        <>
            <div className={styleInquir.container}>
                <div className={styleInquir.form}>
                    <input className={styleInquir.title} placeholder={"Enter the titile"}></input>
                    <input className={styleInquir.text} placeholder={"Enter the text"}></input>
                    <input className={styleInquir.name} placeholder={"Name"}></input>
                    <input className={styleInquir.email} placeholder={"Email"}></input>
                    <button className={styleInquir.button}>전송</button>
                </div>

                <div className={styleInquir.lable}>
                    <div className={styleInquir.line}><Geo/><a><b>주소 : </b>서울특별시 강남구 영동대로 616 4층</a></div>
                    <div className={styleInquir.line}><Tel/><a><b>전화번호 :</b> 070 4651 5197</a></div>
                    <div className={styleInquir.line}><Fax/><a><b>팩스 : </b> 070 8630 3003</a></div>
                    <div className={styleInquir.line}><Mail/><a><b>이메일 : </b> info@korupharma.com</a></div>
                </div>
            </div>
        </>

    );
};

export default InquiriesContent;