import React, {useState} from "react";

import StylesThirdContent from './ThirdContent.module.css'
import { NavLink } from 'react-router-dom';

import OutlinePlus from '../../../../icons/ic_outline-plus.svg'


const SecondAboutUs = ({ picture, heading, link }) => {

    return (
        <NavLink className={StylesThirdContent.thirdContainer} to={link}>
            <div className={StylesThirdContent.thirdContent}>
                <div className={`${StylesThirdContent.thirdPicture} ${picture}`}></div>
                <div className={StylesThirdContent.thirdContentDesc}>
                    <h3>{heading}</h3>
                    <img className={StylesThirdContent.thirdButton} src={OutlinePlus} alt='Plus sign'/>
                </div>
            </div>
        </NavLink>
    );
}

export default SecondAboutUs;