import React from 'react';
import {NavLink} from "react-router-dom";

import StylesNaviSubmenu from './Navi-submenu.module.css';


const NavigationSubmenu = () => {
    
    return (
        <>
            <div className={StylesNaviSubmenu.submenu}>
                <div className={StylesNaviSubmenu.logoImitation}></div>
                <div className={StylesNaviSubmenu.navSubMenu}>
                    <ul>
                        <li><NavLink to="/Ceo">CEO 인사말</NavLink></li>
                        <li><NavLink to="/MissionVision">경영철학</NavLink></li>
                        <li><NavLink to="/HistoryTimeline">연혁</NavLink></li>
                        <li><NavLink to="/Certifications">기업인증</NavLink></li>
                        <li><NavLink to="/Globalnetwork">글로벌 네트워크</NavLink></li>
                        <li><NavLink to="/Locations">사업장 소개</NavLink></li>
                    </ul>
                    <ul>
                        <li><NavLink to="/Factory">공장 소개</NavLink></li>
                    </ul>
                    <ul>
                        <li><NavLink to="/Allproducts">전체 제품</NavLink></li>
                        <li><NavLink to="/Dermalfillers">안면필러</NavLink></li>
                        <li><NavLink to="/Bodyfiller">바디필러</NavLink></li>
                        <li><NavLink to="/Hairbooster">헤어부스터</NavLink></li>
                        <li><NavLink to="/Toxins">보툴리눔톡신</NavLink></li>
                    </ul>
                    <ul>
                        <li><NavLink to="/R&DInstitute">연구소 소개</NavLink></li>
                        <li><NavLink to="/CoreTechnology">핵심기술</NavLink></li>
                        <li><NavLink to="/ResearchField">연구활동</NavLink></li>
                    </ul>
                    <ul>
                        <li><NavLink to="https://korupharma.irpage.co.kr/#/main">IR/PR</NavLink></li>
                    </ul>
                </div>
                <div className={StylesNaviSubmenu.globeImitation}></div>
            </div>
        </>
    );

};

export default NavigationSubmenu;