import React, { useState, useEffect } from 'react';
import styleglobal from './GlobalNetworkContent.module.css'
import map from '../../../img/GLobal Network/map.png'
import Logo from '../../../img/GLobal Network/Logo.png'
import LogoMini1 from '../../../img/GLobal Network/BioIcon.svg'
import LogoMini2 from '../../../img/GLobal Network/CountryIcon.svg'

const GlobalnetworkContent = () => {
    const [currentNumber, setCurrentNumber] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentNumber((prevNumber) => {
                const nextNumber = prevNumber < 170 ? prevNumber + 1 : 0;

                // Clear the interval when the maximum number is reached
                if (nextNumber === 170) {
                    clearInterval(intervalId);
                }

                return nextNumber;
            });
        }, 10); // Set the interval time (in milliseconds)

        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array ensures the effect runs only once on mount

    return (
        <div className={styleglobal.content}>
            <div className={styleglobal.topcontent}>
                <div className={styleglobal.toptext}> 코루파마는 다양한 제품 포트폴리오와 글로벌 고객과의
                    협업을 기반으로 전 세계 약 110여개국에 제품을 수출하며 메디컬 에스테틱 시장의 주요 플레이어로
                    성장해 왔습니다. 또한, 다양한 국제 전시회에 참가하고, ATP 세미나를 통해 고객과 직접 만나는 등
                    18개 언어로 전 세계 고객과 소통하고 있습니다.
                </div>
            </div>
            <h2 className={styleglobal.mobileTitle}>
                ONE STOP SOLUTION
            </h2>
            <div className={styleglobal.container}>
                <img src={map} id={styleglobal.map} alt={"map"}/>
                <div className={styleglobal.blackcase}>
                    <div className={styleglobal.title}>
                        ONE STOP SOLUTION
                    </div>
                    <div className={styleglobal.lineText}>
                        <div className={styleglobal.lineBox}>
                            <img className={styleglobal.koruLogo} src={LogoMini2} alt={"Logo"}/>
                            <div className={styleglobal.numberScroll1}></div>
                            <div className={styleglobal.numberScroll1Mobile}>117</div>
                            <p>개국 - COUNTRIES</p>
                        </div>
                        <div className={styleglobal.lineLogo}>
                            <img src={Logo} alt={"Logo"}/>
                        </div>
                        <div className={styleglobal.lineBox}>
                            <img className={styleglobal.koruLogo} src={LogoMini1} alt={"Logo"}/>
                            <div className={styleglobal.numberScroll2}></div>
                            <div className={styleglobal.numberScroll2Mobile}>1700</div>
                            <p>개 바이어 - BUYERS</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GlobalnetworkContent;