import React from "react";
import Hbar from './TopLableBg.module.css'


const TopLableBG = ({kor,eng,bgimg}) => {
    return (
        <>
            <div className={Hbar.topPadding}></div>
            <div className={Hbar.topbar}>
                <div style={{backgroundImage: 'url(' + bgimg + ')'}} className={Hbar.img}></div>
                <div className={Hbar.title}>
                    <h1>{kor}</h1>
                    <div id={Hbar.line}></div>
                    <a>{eng}</a>
                </div>
            </div>
        </>
    );
};

export default  TopLableBG;