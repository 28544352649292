import StylesNaviSubmenuBurger from './Navi-submenu-burger.module.css';
import React from 'react';
import {NavLink} from "react-router-dom";

import ArrowDown from '../../../icons/arrow-down.svg'

const NavigationSubMenuBurger = ({ content, props }) => {
    
    const choosePage = (content) => {
        switch (content) {
            case '기업소개':
                return (
                    <ul onClick={props} className={StylesNaviSubmenuBurger.navi_submenu_container}>
                        <NavLink to="/Ceo">
                            <li>CEO 인사말</li>
                        </NavLink>
                        <NavLink to="/MissionVision">
                            <li>경영철학</li>
                        </NavLink>
                        <NavLink to="/HistoryTimeline">
                            <li>연혁</li>
                        </NavLink>
                        <NavLink to="/Certifications">
                            <li>기업인증</li>
                        </NavLink>
                        <NavLink to="/Globalnetwork">
                            <li>글로벌 네트워크</li>
                        </NavLink>
                        <NavLink to="/Locations">
                            <li>사업장 소개</li>
                        </NavLink>
                    </ul>
                );
            case '생산':
                return (
                    <ul onClick={props} className={StylesNaviSubmenuBurger.navi_submenu_container}>
                        <NavLink to="/Factory">
                            <li>공장 소개</li>
                        </NavLink>
                    </ul>
                );
            case '제품':
                return (
                    <ul onClick={props} className={StylesNaviSubmenuBurger.navi_submenu_container}>
                        <NavLink to="/Allproducts">
                            <li>전체 제품</li>
                        </NavLink>
                        <NavLink to="/Dermalfillers">
                            <li>안면필러</li>
                        </NavLink>
                        <NavLink to="/Bodyfiller">
                            <li>바디필러</li>
                        </NavLink>
                        <NavLink to="/Hairbooster">
                            <li>헤어부스터</li>
                        </NavLink>
                        <NavLink to="/Toxins">
                            <li>보툴리눔톡신</li>
                        </NavLink>
                    </ul>
                );
            case 'R&D':
                return (
                    <ul onClick={props} className={StylesNaviSubmenuBurger.navi_submenu_container}>
                        <NavLink to="/R&DInstitute">
                            <li>연구소 소개</li>
                        </NavLink>
                        <NavLink to="/CoreTechnology">
                            <li>핵심기술</li>
                        </NavLink>
                        <NavLink to="/ResearchField">
                            <li>연구활동</li>
                        </NavLink>
                    </ul>
                );
            case 'IR/PR':
                return (
                    <ul onClick={props} className={StylesNaviSubmenuBurger.navi_submenu_container}>
                        <NavLink to="https://korupharma.irpage.co.kr/#/main">
                            <li>IR/PR</li>
                        </NavLink>
                    </ul>
                )
            default:
                return (
                    <ul onClick={props} className={StylesNaviSubmenuBurger.navi_submenu_container}>
                        <NavLink to="/Ceo">
                            <li>CEO 인사말</li>
                        </NavLink>
                        <NavLink to="/MissionVision">
                            <li>경영철학</li>
                        </NavLink>
                        <NavLink to="/HistoryTimeline">
                            <li>연혁</li>
                        </NavLink>
                        <NavLink to="/Certifications">
                            <li>기업인증</li>
                        </NavLink>
                        <NavLink to="/Globalnetwork">
                            <li>글로벌 네트워크</li>
                        </NavLink>
                        <NavLink to="/Locations">
                            <li>사업장 소개</li>
                        </NavLink>
                    </ul>
                );
        }
    };
    
    return (
        <>
            {choosePage(content)}
        </>
    );

};

export default NavigationSubMenuBurger;