import React, { useState, useRef } from 'react';
import {
    CSSTransition,
    TransitionGroup,
  } from 'react-transition-group';

import StylesNavimenuBurger from './Navi-menu-burger.module.css';
import './Navi-menu-burger.module.css'
import NavigationSubMenuBurger from '../Burger-submenu/Navi-submenu-burger';
import ArrowRight from '../../../icons/arrow_right.svg'

const NavigationMenuBurger = ({ props }) => {
    console.log(props);

    const [content, setContent] = useState('기업소개');
    const [id, setId] = useState(1);
    const nodeRef = useRef(null);

    const changeContent = (page, open) => {
        setContent(page);
        setId(open);
    };

    return (
        <>
            <div className={StylesNavimenuBurger.navigation_menu_burger}>
                <div className={StylesNavimenuBurger.navigation_menu_header}></div>
                <div className={StylesNavimenuBurger.navigation_menu_content}>
                    <div className={StylesNavimenuBurger.main_navigation}>
                        <li onClick={() => changeContent('기업소개', 1)}>
                            <p>기업소개</p>
                            <img src={ArrowRight} alt='arrow right'/>
                        </li>
                        <li onClick={() => changeContent('생산', 2)}>
                            <p>생산</p>
                            <img src={ArrowRight} alt='arrow right'/>
                        </li>
                        <li onClick={() => changeContent('제품', 3)}>
                            <p>제품</p>
                            <img src={ArrowRight} alt='arrow right'/>
                        </li>
                        <li onClick={() => changeContent('R&D', 4)}>
                            <p>R&D</p>
                            <img src={ArrowRight} alt='arrow right'/>
                        </li>
                        <li onClick={() => changeContent('IR/PR', 5)}>
                            <p>IR/PR</p>
                            <img src={ArrowRight} alt='arrow right'/>
                        </li>
                    </div>
                    <div className={StylesNavimenuBurger.additional_navigation}>
                        <TransitionGroup className={StylesNavimenuBurger.transition_group}>
                            <CSSTransition
                                key={id}
                                timeout={500}
                                classNames={{
                                    enter: StylesNavimenuBurger["item-enter"],
                                    enterActive: StylesNavimenuBurger["item-enter-active"],
                                    exit: StylesNavimenuBurger["item-exit"],
                                    exitActive: StylesNavimenuBurger["item-exit-active"]
                                }}
                            >
                                <NavigationSubMenuBurger
                                    content={content}
                                    props={props}
                                />
                            </CSSTransition>
                        </TransitionGroup>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NavigationMenuBurger;