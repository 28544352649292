import React from "react";
import {ReactComponent as Svg} from '../../../icons/old_icons/date.svg';
import styleCertif from "./CertificateContent.module.css";
import Photo1 from "../../../img/Certificate/1.jpg"
import Photo2 from "../../../img/Certificate/2.jpg"
import Photo3 from "../../../img/Certificate/3.jpg"
import Photo4 from "../../../img/Certificate/4.jpg"
import Photo5 from "../../../img/Certificate/5.jpg"
import Photo6 from "../../../img/Certificate/6.jpg"
import Photo7 from "../../../img/Certificate/7.jpg"
import Photo8 from "../../../img/Certificate/8.jpg"

const Certificate = () => {

    return (
        <div className={styleCertif.content}>
            <div className={styleCertif.container}>
                <div className={styleCertif.photo}>
                    <img src={Photo4} alt={4}/>
                </div>
                <div className={styleCertif.text}>
                    <div className={styleCertif.lable}>
                        <h1>GMP 인증</h1>
                        <div className={styleCertif.date}><Svg/><p>2022.06.16</p></div>
                    </div>
                    <p>GMP Certification</p>
                </div>
            </div>
            <div className={styleCertif.container}>
                <div className={styleCertif.photo}>
                    <img src={Photo3} alt={3}/>
                </div>
                <div className={styleCertif.text}>
                    <div className={styleCertif.lable}>
                        <h1>의료기기 제조업 허가증</h1>
                        <div className={styleCertif.date}><Svg/><p>2022.03.17</p></div>
                    </div>
                    <p>Medical device manufacturing license</p>
                </div>
            </div>
            <div className={styleCertif.container}>
                <div className={styleCertif.photo}>
                    <img src={Photo5} alt={5}/>
                </div>
                <div className={styleCertif.text}>
                    <div className={styleCertif.lable}>
                        <h1>ISO9001 인증</h1>
                        <div className={styleCertif.date}><Svg/><p>2021.01.07</p></div>
                    </div>
                    <p>ISO Certification</p>
                </div>
            </div>
            <div className={styleCertif.container}>
                <div className={styleCertif.photo}>
                    <img src={Photo6} alt={6}/>
                </div>
                <div className={styleCertif.text}>
                    <div className={styleCertif.lable}>
                        <h1>ISO14001 인증 </h1>
                        <div className={styleCertif.date}><Svg/><p>2021.01.07</p></div>
                    </div>
                    <p>ISO Certification</p>
                </div>
            </div>
            <div className={styleCertif.container}>
                <div className={styleCertif.photo}>
                    <img src={Photo7} alt={7}/>
                </div>
                <div className={styleCertif.text}>
                    <div className={styleCertif.lable}>
                        <h1>벤처기업확인서</h1>
                        <div className={styleCertif.date}><Svg/><p>2024.05.20</p></div>
                    </div>
                    <p>Venture business approval</p>
                </div>
            </div>
            <div className={styleCertif.container}>
                <div className={styleCertif.photo}>
                    <img src={Photo2} alt={2}/>
                </div>
                <div className={styleCertif.text}>
                    <div className={styleCertif.lable}>
                        <h1>기업부설연구소 인정서</h1>
                        <div className={styleCertif.date}><Svg/><p>2022.01.12</p></div>
                    </div>
                    <p>Company-affiliated research institute certificate</p>
                </div>
            </div>
            <div className={styleCertif.container} id={styleCertif.mobileId}>
                <div className={styleCertif.photo}>
                    <img src={Photo8} alt={8}/>
                </div>
                <div className={styleCertif.text}>
                    <div className={styleCertif.lable}>
                        <h1>경영혁신형 중소기업 (Main-Biz) 확인서</h1>
                        <div className={styleCertif.date}><Svg/><p>2024.01.14</p></div>
                    </div>
                    <p> (Main-biz) certificate for innovation management in small and medium Business </p>
                </div>
            </div>
            <div className={styleCertif.container}>
                <div className={styleCertif.photo}>
                    <img src={Photo1} alt={1}/>
                </div>
                <div className={styleCertif.text}>
                    <div className={styleCertif.lable}>
                        <h1>의약품 도매상 허가증</h1>
                        <div className={styleCertif.date}><Svg/><p>2023.02.06</p></div>
                    </div>
                    <p>Pharmaceutical Wholesaler Permit</p>
                </div>
            </div>
        </div>
    );
};

export default Certificate;