import React from "react";
import styleSiteMap from "./SitemapContent.module.css"
import TLeft from "../Universal/ContentLable";
import {NavLink} from "react-router-dom";



const SitemapContent = () => {

    return (
        <>
            <div className={styleSiteMap.container}>
                <div className={styleSiteMap.collum}>
                    <h1><NavLink to="/Ceo">기업소개</NavLink></h1>
                    <li><NavLink to="/Ceo">CEO 인사말</NavLink></li>
                    <li><NavLink to="/MissionVision">경영철학</NavLink></li>
                    <li><NavLink to="/HistoryTimeline">연혁</NavLink></li>
                    <li><NavLink to="/Certifications">기업인증</NavLink></li>
                    <li><NavLink to="/Globalnetwork">글로벌 네트워크</NavLink></li>
                    <li><NavLink to="/Locations">사업장 소개</NavLink></li>
                </div>
                <div className={styleSiteMap.line}></div>
                <div className={styleSiteMap.collum}>
                    <h1><NavLink to="/Factory">생산</NavLink></h1>
                    <li><NavLink to="/Factory">공장 소개</NavLink></li> 
                </div>
                <div className={styleSiteMap.line}></div>
                <div className={styleSiteMap.collum}>
                    <h1><NavLink to="/Allproducts">제품</NavLink></h1>
                    <li><NavLink to="/Allproducts">전체 제품</NavLink></li>
                    <li><NavLink to="/Dermalfillers">안면필러</NavLink></li>
                    <li><NavLink to="/Bodyfiller">바디필러</NavLink></li>
                    <li><NavLink to="/Hairbooster">헤어부스터</NavLink></li>
                    <li><NavLink to="/Toxins">보툴리눔톡신</NavLink></li>
                </div>
                <div className={styleSiteMap.line}></div>
                <div className={styleSiteMap.collum}>
                    <h1><NavLink to="/Ceo">R&D</NavLink></h1>
                    <li><NavLink to="/R&DInstitute">연구소 소개</NavLink></li>
                    <li><NavLink to="/CoreTechnology">핵심기술</NavLink></li>
                    <li><NavLink to="/ResearchField">연구활동</NavLink></li>
                </div>
                <div className={styleSiteMap.line}></div>
                <div className={styleSiteMap.collum}>
                    <h1><NavLink to="/Ceo">IR/PR</NavLink></h1>
                    <li><NavLink to="/ResearchField">IR/PR</NavLink></li>
                </div>
            </div>
        </>

    );
};

export default SitemapContent;