import React from "react";
import Hbar from '../../Components/PagesComponents/Universal/History-bar'
import TitleBar from '../../Components/PagesComponents/Universal/TopLableBg'
import {NavLink} from "react-router-dom";
import LabContent from "../../Components/PagesComponents/R&D/R&DInstituteContent"
import Bg from "../../img/R&D/R&D_Header.png";


const RDCenter = () => {
    return (
        <>
            <TitleBar kor={"연구소 소개"} eng={"R&D Institute"} bgimg={Bg}/>
            <Hbar lvl1={"R&D"} lvl2={"연구소 소개"}
                  nav1={<li><NavLink to="/R&DInstitute">연구소 소개</NavLink></li>}
                  nav2={<li><NavLink to="/CoreTechnology">핵심기술</NavLink></li>}
                  nav3={<li><NavLink to="/ResearchField">연구활동</NavLink></li>}>
            </Hbar>
            <LabContent/>
        </>
    );
};
export default RDCenter;