import React from "react";
import InquiriesContent from "../../Components/PagesComponents/FotterLinks/InquiriesContent";
import TopLableBG from "../../Components/PagesComponents/Universal/TopLableBg";
import Bg from "../../img/bakcground/BgFooter.png";



const Inquiries = () => {

    return (
        <>
            <TopLableBG bgimg={Bg} eng={"Inquiries"} kor={"문의"} />
            <InquiriesContent />
        </>

    );
};

export default Inquiries;