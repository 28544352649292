import React from "react";
import TitleBar from "../../Components/PagesComponents/Universal/TopLableBg";
import Hbar from "../../Components/PagesComponents/Universal/History-bar";
import {NavLink} from "react-router-dom";
import HairBoosterContent from "../../Components/PagesComponents/ProductUnique/HairBoosterContent";
import Bg from "../../img/bakcground/BgProduct.png";



const Hairbooster = () => {

    return (
        <>
            <TitleBar kor={"헤어부스터"} eng={"Hair Booster"} bgimg={Bg}/>
            <Hbar lvl1={"제품"} lvl2={"헤어부스터"}
                  nav1={<li><NavLink to="/Allproducts"> 전체 제품 </NavLink></li>}
                  nav2={<li><NavLink to="/Dermalfillers"> 안면필러 </NavLink></li>}
                  nav3={<li><NavLink to="/Bodyfiller"> 바디필러 </NavLink></li>}
                  nav4={<li><NavLink to="/Hairbooster"> 헤어부스터</NavLink></li>}
                  nav5={<li><NavLink to="/Toxins">톡신</NavLink></li>}/>
            <HairBoosterContent />
        </>

    );
};

export default Hairbooster;