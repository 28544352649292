import React from "react";
import styleTechno from "./CoreTechnologyContent.module.css"
import MCO from '../../../img/R&D/Core Tech/MCO Tech_MCO Tech.png'
import Infographic1 from "../../../img/R&D/Core Tech/Infographics_당사의 Avalon Grand Plus의.png"
import Infographic2 from "../../../img/R&D/Core Tech/Infographics_일정한 주입력 유지.jpg"
import InfographicSlider from "../../../img/R&D/Core Tech/MCO Tech Filler-01.jpg"
import MP from '../../../img/R&D/Core Tech/MCO Tech_MP_02.png'
import {useInView} from "react-intersection-observer";

import MCOTech from '../../../img/R&D/Core Tech/Screenshot 15-04-2024 161223.jpg'
import MCOTech1 from '../../../img/R&D/Research Field/MCO Tech 1.png'
import MCOTech2 from '../../../img/R&D/Research Field/MCO Tech 2.png'
import MCOTech3 from '../../../img/R&D/Research Field/MCO Tech 3.png'
import MCOTech4 from '../../../img/R&D/Research Field/MCO Tech 4.png'

const CoreTechnologyContent = () => {
    const [ref, inView] = useInView({
        triggerOnce: true, // Set to true if you want to trigger the animation only once
    });
    const [ref1, inView1] = useInView({
        triggerOnce: true, // Set to true if you want to trigger the animation only once
    });
    const [ref2, inView2] = useInView({
        triggerOnce: true, // Set to true if you want to trigger the animation only once
    });
    const [ref3, inView3] = useInView({
        triggerOnce: true, // Set to true if you want to trigger the animation only once
    });
    const [ref4, inView4] = useInView({
        triggerOnce: true,
        delay: 1000,
    });
    return (
        <>
            <div className={styleTechno.container}>
                <div
                    ref={ref}
                    className={`${styleTechno.box} ${inView ? styleTechno.animate0 : ''}`}>
                    <div className={styleTechno.titlebig}>코루파마 독자 기술 MCO® Tech.</div>
                    <div className={styleTechno.photo}>
                        <img src={MCO} alt={"MCO"}/>
                    </div>
                    <div className={styleTechno.textSub1}>
                        자체 개발한 우수한 가교기술과 수준높은 생산기술로 시술자에게는 편리함을, 환자에게는 안전성과 만족도를 극대화합니다.
                    </div>
                    <div className={styleTechno.textSub2}>
                        <b>MCO® Tech.는</b> 히알루론산(HA)의 가교반응 조건을 최적화하여 안전성을 극대화한 제품을 생산할 수 있는 당사의 고유한 가교기술 입니다. 당사는
                        독자적인 가교기술을 통해 가교제 사용을 최소화하며 엄격한 투석공정 관리를 통해 BDDE 가교제의 농도를 측정 가능 기준치의 최하 수준으로 유지하고 있습니다. 당사의 뛰어난
                        가교기술은 가교반응의 효율을 극대화 하여 균일한 입자 크기, 안정적 점탄성 유지, 일정하고 부드러운 주입력 등 고품질 필러 제형을 제조할 수 있는 기반이 됩니다.
                    </div>
                </div>
                <div className={`${styleTechno.line} ${inView ? styleTechno.animate0 : ''}`}></div>
                <div ref={ref1}
                     className={`${styleTechno.box2}`}>
                    <div className={`${styleTechno.titlebig2} ${inView1 ? styleTechno.animate0 : ''}`}>
                        독자적 가교기술 및 여과기술로 안전성 확보
                    </div>
                    <div className={`${styleTechno.titlesmall2} ${inView1 ? styleTechno.animate0 : ''}`}>
                        <b>MCO® Tech.</b>의 높은 가교효율과 여과기술 → 생체독성을 띠는 가교제 최소화 → 높은 안전성
                    </div>
                    <div className={styleTechno.photoMobile}>
                        <img src={MCOTech} alt={"MCO"}/>
                    </div>
                    <div ref={ref4} className={styleTechno.MCO}>
                        <div className={`${styleTechno.MCOContainer} ${inView4 ? styleTechno.animateBefore : ''}`}>
                            <div>BEFORE</div>
                            <img className={styleTechno.MCOImage} src={MCOTech1}></img>
                            <div className={styleTechno.MCOSubText}>HA 용액</div>
                        </div>
                        <div className={`${styleTechno.MCOContainer} ${inView4 ? styleTechno.animateAfter : ''}`}>
                            <div>AFTER</div>
                            <img className={styleTechno.MCOImage} src={MCOTech2}></img>
                            <div className={styleTechno.MCOSubText}>HA 젤</div>
                        </div>
                        <div className={`${styleTechno.MCOContainer2} ${inView4 ? styleTechno.animateTech : ''}`}>
                            <div className={styleTechno.MCOSubcontainer}>
                                <img className={styleTechno.MCOImage2} src={MCOTech3}></img>
                                <div className={styleTechno.MCOSubcontainerText}>
                                    <p><b>저품질 필러</b></p>
                                    <p>(낮은 가교반응 효율)</p>
                                </div>
                            </div>
                            <div className={`${styleTechno.MCOSubcontainer} ${inView4 ? styleTechno.animateColorFill : ''}`}>
                                <img className={styleTechno.MCOImage2} src={MCOTech4}></img>
                                <div className={styleTechno.MCOSubcontainerText}>
                                    <p><b>MCO® Tech.</b></p>
                                    <p>(높은 가교반응 효율)</p>
                                </div>
                            </div>
                        </div>
                        <div className={`${styleTechno.MCOInfo} ${inView4 ? styleTechno.animateInfo : ''}`}>
                            <div className={styleTechno.MCOInfoAdd}>
                                <p>가교제 잔류량</p>
                            </div>
                            <div className={styleTechno.MCOInfoFirst}>
                                <p>식약처 기준</p>
                                <p>2 ppm 이하</p>
                            </div>
                            <div className={styleTechno.MCOInfoSecond}>
                                <p>당사 현황</p>
                                <p>Not detected</p>
                            </div>
                        </div>
                        <div className={`${styleTechno.BDDE} ${inView4 ? styleTechno.animateBDDE : ''}`}>
                            <p>BDDE</p>
                            <p>(가교제) 첨가</p>
                            <div className={styleTechno.empty}></div>
                            <p>가교반응</p>
                        </div>
                        <div className={`${styleTechno.MCOCircle} ${inView4 ? styleTechno.animateCircle : ''}`}></div>
                        <div className={`${styleTechno.line1} ${inView4 ? styleTechno.animateHorizontal : ''}`}></div>
                        <div className={`${styleTechno.line2} ${inView4 ? styleTechno.animateHorizontal : ''} ${inView4 ? styleTechno.animateHorizontalColor : ''}`}></div>
                        <div className={`${styleTechno.line3} ${inView4 ? styleTechno.animateVertical : ''}`}></div>
                        <div className={`${styleTechno.line4} ${inView4 ? styleTechno.animateVertical : ''} ${inView4 ? styleTechno.animateVerticalColor : ''}`}></div>
                        <div className={`${styleTechno.line5} ${inView4 ? styleTechno.animateHorizontal2 : ''}`}></div>
                        <div className={`${styleTechno.line6} ${inView4 ? styleTechno.animateBDDE : ''}`}></div>
                        <div className={`${styleTechno.triangle} ${inView4 ? styleTechno.animateBDDE : ''}`}></div>
                    </div>
                </div>
                <div className={`${styleTechno.line} ${inView2 ? styleTechno.animate0 : ''}`}></div>
                <div ref={ref2}
                     className={`${styleTechno.box} ${inView2 ? styleTechno.animate0 : ''}`}>
                    <div className={styleTechno.titlebig}>
                        수준 높은 생산기술로 고품질 필러 생산
                    </div>
                    <div className={styleTechno.titlesmall}>
                        정교한 물성제어를 바탕으로 용도와 주름에 맞는 필러 제품군 확보로 성형성과 편의성이 극대화된 고품질 필러 제품 생산
                    </div>
                    <div className={styleTechno.imagesContainer}>
                        <img className={styleTechno.infographicSlider} src={InfographicSlider} alt="gif-gel"/>
                        <div className={styleTechno.infographics}>
                            <img className={styleTechno.infographic1} src={Infographic1} alt="info"/>
                            <img className={styleTechno.infographic2} src={Infographic2} alt="info"/>
                        </div>
                    </div>
                    <div className={styleTechno.titlesmall}>
                        필러 젤 제조 후, 입자크기를 일정하게 유지하도록 하는 입자 분쇄 기술을 통해 일정하고 안정적인 주입력 유지
                    </div>
                </div>
                <div className={`${styleTechno.line} ${inView3 ? styleTechno.animate0 : ''}`}></div>
                <div ref={ref3}
                     className={`${styleTechno.box} ${inView3 ? styleTechno.animate0 : ''}`}>
                    <div className={styleTechno.titlebig}>
                        계단식으로 정교하게 디자인 된 물성
                    </div>
                    <div className={styleTechno.titlesmall}>
                        개인화, 세분화되는 시술 니즈에 부합하는 다양한 라인업 시술 용도에 따라 최적화된 계단식 물성
                    </div>
                    <div className={styleTechno.gifGelContainer}>
                        <img className={styleTechno.gifMP} src={MP} alt="gif-gel"/>
                    </div>
                    <div className={styleTechno.titlesmall}>
                        정교한 히알루론산 가교기술을 활용해 다양한 시술에 최적의 제품을 적용할 수 있도록 제품을 디자인 할 수 있는 역량을 확보
                    </div>
                </div>
            </div>
        </>
    );
};

export default CoreTechnologyContent;