import React from "react";
import TitleBar from "../../Components/PagesComponents/Universal/TopLableBg";
import Hbar from "../../Components/PagesComponents/Universal/History-bar";
import {NavLink} from "react-router-dom";
import ToxinContent from "../../Components/PagesComponents/ProductUnique/ToxinContent";
import Bg from "../../img/bakcground/BgProduct.png";



const Toxins = () => {

    return (
        <>
            <TitleBar kor={"보툴리눔톡신"} eng={"Botulinum Toxin"} bgimg={Bg}/>
            <Hbar lvl1={"제품"} lvl2={"보툴리눔톡신"}
                  nav1={<li><NavLink to="/Allproducts"> 전체 제품 </NavLink></li>}
                  nav2={<li><NavLink to="/Dermalfillers"> 안면필러 </NavLink></li>}
                  nav3={<li><NavLink to="/Bodyfiller"> 바디필러 </NavLink></li>}
                  nav4={<li><NavLink to="/Hairbooster"> 헤어부스터</NavLink></li>}
                  nav5={<li><NavLink to="/Toxins">톡신</NavLink></li>}/>
            <ToxinContent/>
        </>

    );
};

export default Toxins;