import React from "react";
import StylesFirstPage from './FirstPage.module.css'

const FirstPage = () => {

    return (
        <div className={`${StylesFirstPage.firstPageBg} ${StylesFirstPage.animateBG}`}>
        {/* // <div className={`${StylesFirstPage.firstPageBg} `}> */}
            <div className={StylesFirstPage.firstPageHeading}>
                <h2 className={`${ StylesFirstPage.animate1 }`}>
                    KORU PHARMA
                </h2>
                <div className={`${StylesFirstPage.firstPageDescription} ${ StylesFirstPage.animate2}`}>
                    <h1>MADE WITH NEW TECHNOLOGY & HIGH QUALITY</h1>
                    <p>고기능성 제품의 연구개발과 엄격한 품질관리를 통해 프리미엄 제품을 제공하는 메디컬 에스테틱 전문기업</p>
                </div>
            </div>
        </div>
    );
}

export default FirstPage;