import React from "react";
import stylelocation from './LocationsContent.module.css'
import {ReactComponent as Geo} from '../../../icons/old_icons/geo.svg';
import {ReactComponent as Tel} from '../../../icons/old_icons/Tel.svg';
import {ReactComponent as Fax} from '../../../icons/old_icons/fax.svg';
import {ReactComponent as Mail} from '../../../icons/old_icons/email.svg';
import { useEffect, useRef } from 'react';

const LocationsContent = () => {
    const mapElement1 = useRef(null);
    useEffect(() => {
        const { naver } = window;
        if (!mapElement1.current || !naver) return;

        const location = new naver.maps.LatLng(37.51210, 127.05098);
        const mapOptions: naver.maps.MapOptions = {
            center: location,
            zoom: 17,
            zoomControl: true,
            zoomControlOptions: {
                position: naver.maps.Position.TOP_RIGHT,
            },
        };
        const map = new naver.maps.Map(mapElement1.current, mapOptions);
        new naver.maps.Marker({
            position: location,
            map,
        });
    }, []);
    const mapElement2 = useRef(null);
    useEffect(() => {
        const { naver } = window;
        if (!mapElement2.current || !naver) return;

        const location = new naver.maps.LatLng(37.76597, 127.7785);
        const mapOptions: naver.maps.MapOptions = {
            center: location,
            zoom: 17,
            zoomControl: true,
            zoomControlOptions: {
                position: naver.maps.Position.TOP_RIGHT,
            },
        };
        const map = new naver.maps.Map(mapElement2.current, mapOptions);
        new naver.maps.Marker({
            position: location,
            map,
        });
    }, []);
    const mapElement3 = useRef(null);
    useEffect(() => {
        const { naver } = window;
        if (!mapElement3.current || !naver) return;

        const location = new naver.maps.LatLng(37.55752, 127.20517);
        const mapOptions: naver.maps.MapOptions = {
            center: location,
            zoom: 17,
            zoomControl: true,
            zoomControlOptions: {
                position: naver.maps.Position.TOP_RIGHT,
            },
        };
        const map = new naver.maps.Map(mapElement3.current, mapOptions);
        new naver.maps.Marker({
            position: location,
            map,
        });
    }, []);

    return (
        <div className={stylelocation.container}>
            <div className={stylelocation.location}>
                <div className={stylelocation.map}>
                    <div ref={mapElement1} style={{ minHeight: '100%' }} />
                </div>
                <div className={stylelocation.label}>
                    <h1>본사</h1>
                    <div className={stylelocation.divider}></div>
                    <div className={stylelocation.info}>
                        <div className={stylelocation.line}><Geo className={stylelocation.geoImage}/><a> <b>주소 :</b>서울특별시 강남구 봉은사로 468(삼성동 116-1,2) 16층</a></div>
                        <div className={stylelocation.line}><Tel className={stylelocation.telImage}/><a><b>전화번호 :</b> 070 4651 5197</a></div>
                        <div className={stylelocation.line}><Fax className={stylelocation.faxImage}/><a><b>팩스 :</b> 070 8630 3003</a></div>
                        <div className={stylelocation.line}><Mail className={stylelocation.mailImage}/><a><b>이메일 :</b> info@korupharma.com</a></div>
                    </div>
                </div>
            </div>
            <div className={stylelocation.location}>
                <div className={stylelocation.map}>
                    <div ref={mapElement3} style={{ minHeight: '100%' }} />
                </div>
                <div className={stylelocation.label}>
                    <h1>부설 연구소</h1>
                    <div className={stylelocation.divider}></div>
                    <div className={stylelocation.info}>
                        <div className={stylelocation.line}><Geo className={stylelocation.geoImage}/><a><b>주소 :</b> 경기도 하남시 미사대로 540 B동 721호(현대지식산업센터 한강미사2차)</a></div>
                        <div className={stylelocation.line}><Tel className={stylelocation.telImage}/><a><b>전화번호 :</b> 070 4651 5041</a></div>
                        <div className={stylelocation.line}><Mail className={stylelocation.mailImage}/><a><b>이메일 :</b> info@korupharma.com</a></div>
                    </div>
                </div>
            </div>
            <div className={stylelocation.location}>
                <div className={stylelocation.map}>
                    <div ref={mapElement2} style={{ minHeight: '100%' }} />
                </div>
                <div className={stylelocation.label}>
                    <h1>생산 공장</h1>
                    <div className={stylelocation.divider}></div>
                    <div className={stylelocation.info}>
                        <div className={stylelocation.line}><Geo className={stylelocation.geoImage}/><a><b>주소 :</b> 강원도 춘천시 동산면 원무동길 108-1(동춘천 산업단지)</a></div>
                        <div className={stylelocation.line}><Tel className={stylelocation.telImage}/><a><b>전화번호 :</b> 070 4651 5041</a></div>
                        <div className={stylelocation.line}><Mail className={stylelocation.mailImage}/><a><b>이메일 :</b> info@korupharma.com</a></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LocationsContent;