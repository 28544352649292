import React from "react";
import styleceo from './Page404.module.css'

const Page404 = () => {
    return (
        <>
            <div className={styleceo.container}>
                <h1>404 Page not found</h1>      
            </div>
        </>
    );
};

export default Page404;