import React, {useState} from "react";
import styleFactory from './AboutFactoryContent.module.css'
import Photo1 from "../../../img/Factory/factory1.png";
import Photo2 from "../../../img/Factory/factory2.png";
import Photo3 from "../../../img/Factory/factory3.png";
import Photo4 from "../../../img/Factory/factory4.png";
import YouTube from "react-youtube";

const AboutFactoryContent = () => {

    const [isVisible, setIsVisible] = useState(false);
    const [isVisible2, setIsVisible2] = useState(false);
    const toggleVisibility = () => {
        setIsVisible(!isVisible);

    };

    const toggleVisibility2 = () => {
        setIsVisible2(!isVisible2);
    };

    const divStyle = {
        display: isVisible ? '' : 'none',
    };
    const divStyle2 = {
        display: isVisible2 ? '' : 'none',
    };

    const opts = {
        width: "100%",
        height: "100%",
        playerVars: {
            autoplay: 0
        }
    };

    return (
        <>
            <div className={styleFactory.container}>
                <div className={styleFactory.topcontent}>
                    <h2>공장 소개</h2>
                    <div className={styleFactory.toptext}> 코루파마는 GMP 인증을 받은 제조소와 ISO8 규격의 클린룸을 비롯해, 최신식 자동화 필러 생산 시스템을 갖춘 첨단 공장을 운영하여 높은 품질의 제품을 생산하고 있습니다.
                    </div>
                </div>
                <div className={styleFactory.photoContainer}>
                    <div className={styleFactory.c12}>
                        <img src={Photo1} alt="1"/>
                    </div>
                    <ul>
                        <li>
                            대지면적 8000㎡, 면적 1,833㎡의 2층 규모의 단독 제조소
                        </li>
                        <li>
                            ISO 13485, ISO 9001, ISO 14001 인증 보유
                        </li>
                        <li>
                            교차오염방지, 차압관리, 동선관리를 통해 전 공정 청정도를 최고 상태로 유지
                        </li>
                        <li>
                            의료기기 제조업체에 요구되는 일반 품질시스템 기준 대비 더욱 엄격한 기준 유지
                        </li>
                    </ul>
                </div>
                <div className={styleFactory.c6}>
                    <div className={styleFactory.vcont} onClick={toggleVisibility}>
                        <YouTube className={styleFactory.video} videoId={'xpTHmlqEVm0'}
                                 opts={opts}/>
                    </div>
                    <div className={styleFactory.vcont} onClick={toggleVisibility2}>
                        <YouTube className={styleFactory.video} videoId={'F8KAKe7wg-w'}
                                 opts={opts}/>
                    </div>
                    <div className={styleFactory.play} onClick={toggleVisibility} style={divStyle}>
                        <YouTube className={styleFactory.video} videoId={'xpTHmlqEVm0'}
                                 opts={opts}/>
                        <button onClick={toggleVisibility}>X</button>
                    </div>
                    <div className={styleFactory.play} onClick={toggleVisibility2} style={divStyle2}>
                        <YouTube className={styleFactory.video} videoId={'F8KAKe7wg-w'}
                                 opts={opts}/>
                        <button onClick={toggleVisibility2}>X</button>
                    </div>
                </div>
                <div className={styleFactory.c3}>
                    <div className={styleFactory.box}>
                        <img src={Photo2} alt="2"/>
                        <p>정제수와 청정증기, 클린룸 청정도 유지를 위한 공조시스템 등 <span>고사양 유틸리티 시설 보유</span></p>
                    </div>
                    <div className={styleFactory.box}>
                        <img src={Photo3} alt="3"/>
                        <p><span>전공정 자동화 기기를 활용</span>해 높은 충전 효율 및 품질을 유지</p>
                    </div>
                    <div className={styleFactory.box}>
                        <img src={Photo4} alt="4"/>
                        <p>공기중 0.5㎛이상의 입자가 10,000개 이하인 <span>class 10,000 기준의 청정환경에서 생산</span></p>
                    </div>
                </div>
            </div>
        </>
    );
};


export default AboutFactoryContent;